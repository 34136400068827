import { jsx } from "react/jsx-runtime";
import { usePage } from "@tecnobit-srl/router";
import { createState } from "@tecnobit-srl/state";
import { use } from "i18next";
import { merge } from "lodash-es";
import { useMemo, useEffect } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { useLogger } from "@tecnobit-srl/dev-logger";
const download$7 = {
  wait_download: "<0>Stahování začne během několika vteřin. Jestliže stahování nezačne automaticky,<1>klikněte zde</1>a stahování začne.</0>"
};
const form$7 = {
  required: "Toto pole je povinné",
  minLength: "Napište nejméně {{num}} znaků",
  maxLength: "Maximální počet povolených znaků {{num}}",
  pattern: "Tato hodnota není platná",
  min: "Minimální počet znaků {{num}}",
  max: "Maximální počet znaků {{num}}",
  add_file: "Přidat soubor",
  too_many_files: "Příliš mnoho souborů",
  too_few_files: "Příliš málo aouborů",
  file_too_big: "Vybraný soubor je příliš velký",
  invalid_file: "Vybraný soubor není platný",
  max_files: "Maximální počet souborů",
  min_files: "Minimální počet souborů",
  types_allowed: "Povolené soubory",
  max_weight: "Maximální velikost souborů",
  submit_file_is_uploading: "Musíte počkat pro odeslání, abyste mohl skončit před podáním formuláře",
  upload_error: "Došlo k chybě při odesílání vašeho souboru. Prosím, zkuste znovu.",
  submit_validation_error: "Došlo k chybě během podání vašeho souboru. Prosím, zkontrolujte data a zkuste znovu.",
  submit_error: "Došlo k chybě během podání vašeho souboru.. Jestliže problém trvá, kontaktujte nás.",
  privacy: "Četl jsem a souhlasím se <1><0>Zásadami soukromí</0></1>",
  terms: "Četl jsem a souhlasím se <1><0>smluvními podmínkami</1>.",
  login_prefill: "<0>Přihlaste se</0> pro automatické doplnění vašich informací do formuláře.",
  signup_suggestion: "<0>Přihlaste se nyní</0> pro doplnění vašich dat do formulářů!",
  captcha_not_resolved: "Musíte potvrdit, že jste člověk před odesláním formuláře.",
  invalid_phone_prefix: "Předčíslí není platné",
  invalid_email: "Neplatná email adresa",
  select: {
    create: "{userInput}",
    loading: "Nahrávání...",
    no_options: "Žádné možnosti"
  },
  invalid_phone: "Telefonní číslo je neplatné"
};
const pricing$7 = {
  recommended: "Doporučeno",
  discounted_to: "Podpora končí {{date}}",
  per_month: "měsíčně",
  per_year: "ročně",
  free: "ZDARMA",
  badge_new: "nový",
  price_not_available: "Cena není přechodně k dispozici",
  btn_buy_now: "Kupte nyní",
  badge_video: "video",
  incremental_version: "Vše, co bylo nabízeno v předchozích verzích a více:",
  prices_starting_from: "Ceny začínají na"
};
const yes$7 = "ano";
const no$7 = "ne";
const next$7 = "Další";
const back$7 = "Zpět";
const language$7 = "Jazyk";
const preferences$7 = "Předvolby";
const go_back$7 = "Krok zpět";
const open$7 = "Otevřít";
const close$7 = "Zavřít";
const login$7 = "Přihlásit";
const login_error$7 = "Něco se pokazilo. Prosím, zkuste znovu.";
const no_video_found$7 = "Video nenalezeno.";
const watch_playlist_on_youtube$7 = "Podívejte se na videa na YouTube";
const search_video$7 = "Hledat video";
const logout$7 = "Odhlásit";
const admin_panel$7 = "Panel systému";
const my_products$7 = "Mé výrobky";
const profile$7 = "Profil";
const starting_from$7 = "Začínající od";
const address$7 = "Adresa";
const more_info$7 = "Více informací";
const click_to_expand$7 = "Kliknout pro rozbalení";
const skip$7 = "Skok";
const signup$7 = "Přihlásit";
const loading$7 = "Nahrávání...";
const cookie_policy$7 = "Nastavení cookie";
const privacy_policy$7 = "Nastavení soukromí";
const warning$7 = "Varování";
const error$7 = "Chyba";
const no_results$7 = "Nebyly nalezeny žádné výsledky.";
const read_more$7 = "Read more";
const recaptcha$7 = "Chráněno ověřením";
const captcha$7 = {
  error_generic: "Nelze ověřit. Prosím, znovu načtěte stránku a zkuste to znovu.",
  error_threshold: "Zdá se, že nejste člověk. Počkejte prosím několik minut a zkuste to znovu."
};
const feed_not_available$7 = "Krmivo není k dispozici";
const no_elements_found$7 = "Nebyly nalezeny žádné prvky";
const previous_page$7 = "Předchozí";
const next_page$7 = "Další";
const docenti$7 = "docenti";
const yearly$7 = "Roční";
const monthly$7 = "Měsíční";
const share_on$7 = "Sdílet na";
const show_more$7 = "Zobrazit více...";
const show_less$7 = "Ukaž méně";
const csCore = {
  download: download$7,
  form: form$7,
  pricing: pricing$7,
  yes: yes$7,
  no: no$7,
  next: next$7,
  back: back$7,
  language: language$7,
  preferences: preferences$7,
  go_back: go_back$7,
  open: open$7,
  close: close$7,
  login: login$7,
  login_error: login_error$7,
  no_video_found: no_video_found$7,
  watch_playlist_on_youtube: watch_playlist_on_youtube$7,
  search_video: search_video$7,
  logout: logout$7,
  admin_panel: admin_panel$7,
  my_products: my_products$7,
  profile: profile$7,
  starting_from: starting_from$7,
  address: address$7,
  more_info: more_info$7,
  click_to_expand: click_to_expand$7,
  skip: skip$7,
  signup: signup$7,
  loading: loading$7,
  cookie_policy: cookie_policy$7,
  privacy_policy: privacy_policy$7,
  warning: warning$7,
  error: error$7,
  no_results: no_results$7,
  read_more: read_more$7,
  recaptcha: recaptcha$7,
  captcha: captcha$7,
  feed_not_available: feed_not_available$7,
  no_elements_found: no_elements_found$7,
  previous_page: previous_page$7,
  next_page: next_page$7,
  docenti: docenti$7,
  yearly: yearly$7,
  monthly: monthly$7,
  share_on: share_on$7,
  show_more: show_more$7,
  show_less: show_less$7
};
const en$7 = "English";
const it$7 = "Italiano";
const es$7 = "Español";
const fr$7 = "Français";
const de$7 = "Deutsch";
const pt$7 = "Português";
const pl$7 = "Polskie";
const ru$7 = "Русский";
const cs$7 = "Čeština";
const hu$7 = "Magyar";
const csLanguages = {
  en: en$7,
  "en-GB": "English",
  it: it$7,
  "it-IT": "Italiano",
  es: es$7,
  "es-ES": "Español",
  fr: fr$7,
  "fr-FR": "Français",
  de: de$7,
  "de-DE": "Deutsch",
  pt: pt$7,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$7,
  "pl-PL": "Polskie",
  ru: ru$7,
  "ru-RU": "Русский",
  cs: cs$7,
  "cs-CZ": "Čeština",
  hu: hu$7,
  "hu-HU": "Magyar"
};
const download$6 = {
  wait_download: "<0>Der Download sollte in wenigen Sekunden beginnen. Wenn es nicht automatisch startet, <1>klicken Sie hier</1> , um die Datei herunterzuladen.</0>"
};
const form$6 = {
  required: "Dieses Bereich wird benötigt",
  minLength: "Geben Sie mindestens {{num}} Zeichen ein",
  maxLength: "Es sind maximal {{num}} Zeichen erlaubt",
  pattern: "Der Wert ist ungültig",
  min: "Zulässiger Mindestwert: {{num}}",
  max: "Maximal zulässiger Wert: {{num}}",
  add_file: "Datei hinzufügen",
  too_many_files: "Zu viele Dateien",
  too_few_files: "Zu wenige Dateien",
  file_too_big: "Die ausgewählte Datei ist zu groß",
  invalid_file: "Die ausgewählte Datei ist ungültig",
  max_files: "Maximale Anzahl von Dateien",
  min_files: "Mindestanzahl von Dateien",
  types_allowed: "Dateien erlaubt",
  max_weight: "Maximale Dateigröße",
  submit_file_is_uploading: "Sie müssen warten, bis der Upload abgeschlossen ist, bevor Sie Ihr Formular absenden.",
  upload_error: "Beim Hochladen Ihrer Datei ist etwas schief gelaufen. Bitte versuche es erneut",
  submit_validation_error: "Beim Absenden Ihres Formulars ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
  submit_error: "Beim Absenden Ihres Formulars ist etwas schief gelaufen. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
  privacy: "Ich habe die <1><0>Datenschutzerklärung</0></1> gelesen und stimme ihr zu",
  terms: "Ich habe die <1>Nutzungsbedingungen</1> gelesen und stimme ihnen zu.",
  login_prefill: "<0>Anmelden</0>, um Ihre Informationen automatisch in das Formular einzutragen.",
  signup_suggestion: "<0>Melden Sie sich jetzt an</0>, um Ihre Daten in unseren Formularen vorab auszufüllen!",
  captcha_not_resolved: "Sie müssen bestätigen, dass Sie ein Mensch sind, bevor Sie Ihr Formular absenden.",
  invalid_phone_prefix: "Präfix ungültig",
  invalid_email: "Email adresse nicht gültig",
  select: {
    create: "{userInput}",
    loading: "Wird geladen...",
    no_options: "Keine Optionen gefunden"
  },
  invalid_phone: "Die Telefonnummer ist ungültig"
};
const pricing$6 = {
  recommended: "Empfohlen",
  discounted_to: "Angebot endet am {{date}}",
  per_month: "Monat",
  per_year: "Jahr",
  free: "FREE",
  badge_new: "neu",
  price_not_available: "Preis vorübergehend nicht verfügbar",
  btn_buy_now: "Kaufe jetzt",
  badge_video: "Video",
  incremental_version: "Alles, was in der vorherigen Version angeboten wurde und mehr:",
  prices_starting_from: "Preise ab"
};
const yes$6 = "Ja";
const no$6 = "Nein";
const next$6 = "Nächste";
const back$6 = "Zurück";
const language$6 = "Sprache";
const preferences$6 = "Einstellungen";
const go_back$6 = "Geh zurück";
const open$6 = "Offen";
const close$6 = "schließen";
const login$6 = "Login";
const login_error$6 = "Etwas ist schief gelaufen. Bitte versuche es erneut.";
const no_video_found$6 = "Kein Video gefunden.";
const watch_playlist_on_youtube$6 = "Sehen Sie sich die gesamte Playlist auf YouTube an";
const search_video$6 = "Videos suchen";
const logout$6 = "Logout";
const admin_panel$6 = "Admin Paneele";
const my_products$6 = "Meine Produkte";
const profile$6 = "Profil";
const starting_from$6 = "Ab";
const address$6 = "Adresse";
const more_info$6 = "Mehr Informationen";
const click_to_expand$6 = "Zum erweitern klicken";
const skip$6 = "überspringen";
const signup$6 = "Anmelden";
const loading$6 = "Wird geladen...";
const cookie_policy$6 = "Cookie-Richtlinie";
const privacy_policy$6 = "Datenschutz-Bestimmungen";
const warning$6 = "Warnung";
const error$6 = "Fehler";
const no_results$6 = "Keine Ergebnisse gefunden.";
const read_more$6 = "Read more";
const recaptcha$6 = "Geschützt durch reCAPTCHA";
const captcha$6 = {
  error_generic: "Das Captcha konnte nicht überprüft werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
  error_threshold: "Es scheint, dass du kein Mensch bist. Bitte warten Sie ein paar Minuten und versuchen Sie es erneut."
};
const feed_not_available$6 = "Feed nicht verfügbar";
const no_elements_found$6 = "Keine Elemente gefunden";
const previous_page$6 = "Vorherige";
const next_page$6 = "Nächste";
const docenti$6 = "docenti";
const yearly$6 = "Jährlich";
const monthly$6 = "Monatlich";
const share_on$6 = "Teilen auf";
const show_more$6 = "Zeig mehr...";
const show_less$6 = "Zeige weniger";
const deCore = {
  download: download$6,
  form: form$6,
  pricing: pricing$6,
  yes: yes$6,
  no: no$6,
  next: next$6,
  back: back$6,
  language: language$6,
  preferences: preferences$6,
  go_back: go_back$6,
  open: open$6,
  close: close$6,
  login: login$6,
  login_error: login_error$6,
  no_video_found: no_video_found$6,
  watch_playlist_on_youtube: watch_playlist_on_youtube$6,
  search_video: search_video$6,
  logout: logout$6,
  admin_panel: admin_panel$6,
  my_products: my_products$6,
  profile: profile$6,
  starting_from: starting_from$6,
  address: address$6,
  more_info: more_info$6,
  click_to_expand: click_to_expand$6,
  skip: skip$6,
  signup: signup$6,
  loading: loading$6,
  cookie_policy: cookie_policy$6,
  privacy_policy: privacy_policy$6,
  warning: warning$6,
  error: error$6,
  no_results: no_results$6,
  read_more: read_more$6,
  recaptcha: recaptcha$6,
  captcha: captcha$6,
  feed_not_available: feed_not_available$6,
  no_elements_found: no_elements_found$6,
  previous_page: previous_page$6,
  next_page: next_page$6,
  docenti: docenti$6,
  yearly: yearly$6,
  monthly: monthly$6,
  share_on: share_on$6,
  show_more: show_more$6,
  show_less: show_less$6
};
const en$6 = "English";
const it$6 = "Italiano";
const es$6 = "Español";
const fr$6 = "Français";
const de$6 = "Deutsch";
const pt$6 = "Português";
const pl$6 = "Polskie";
const ru$6 = "Русский";
const cs$6 = "Čeština";
const hu$6 = "Magyar";
const deLanguages = {
  en: en$6,
  "en-GB": "English",
  it: it$6,
  "it-IT": "Italiano",
  es: es$6,
  "es-ES": "Español",
  fr: fr$6,
  "fr-FR": "Français",
  de: de$6,
  "de-DE": "Deutsch",
  pt: pt$6,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$6,
  "pl-PL": "Polskie",
  ru: ru$6,
  "ru-RU": "Русский",
  cs: cs$6,
  "cs-CZ": "Čeština",
  hu: hu$6,
  "hu-HU": "Magyar"
};
const download$5 = {
  wait_download: "<0>Download should starts in a few seconds. If it doesn't start automatically, <1>click here</1> to download the file.</0>"
};
const form$5 = {
  required: "This field is required",
  minLength: "Type at least {{num}} characters",
  maxLength: "Max {{num}} characters are allowed",
  pattern: "The value is not valid",
  min: "Minimum value allowed: {{num}}",
  max: "Maximum value allowed: {{num}}",
  add_file: "Add file",
  too_many_files: "Too many files",
  too_few_files: "Too few files",
  file_too_big: "The selected file is too big",
  invalid_file: "The selected file is not valid",
  max_files: "Max number of files",
  min_files: "Min number of files",
  types_allowed: "Files allowed",
  max_weight: "Max file size",
  submit_file_is_uploading: "You need to wait for the upload to finish before submitting your form.",
  upload_error: "Something went wrong while uploading your file. Please, try again.",
  submit_validation_error: "Something went wrong while submitting your form. Please, check your data and try again.",
  submit_error: "Something went wrong while submitting your form. If the problem persists, please contact us.",
  privacy: "I have read and agreed to the <1><0>Privacy Policy</0></1>",
  terms: "I have read and agreed to the <1>terms and conditions</1>.",
  login_prefill: "<0>Login</0> to automatically fill your information in the form.",
  signup_suggestion: "<0>Sign up now</0> to pre-fill your data in our forms!",
  captcha_not_resolved: "You have to confirm that you are a human before submitting your form.",
  invalid_phone_prefix: "Prefix not valid",
  invalid_email: "Email address not valid",
  select: {
    create: "{userInput}",
    loading: "Loading...",
    no_options: "No options found"
  },
  invalid_phone: "The phone number is invalid"
};
const pricing$5 = {
  recommended: "Recommended",
  discounted_to: "Promotion ends {{date}}",
  per_month: "month",
  per_year: "year",
  free: "FREE",
  badge_new: "new",
  price_not_available: "Price temporarily not available",
  btn_buy_now: "Buy now",
  badge_video: "video",
  incremental_version: "Everything offered in the previous version and more:",
  prices_starting_from: "Prices starting from"
};
const yes$5 = "yes";
const no$5 = "no";
const next$5 = "Next";
const back$5 = "Back";
const language$5 = "Language";
const preferences$5 = "Preferences";
const go_back$5 = "Go back";
const open$5 = "Open";
const close$5 = "Close";
const login$5 = "Login";
const login_error$5 = "Something went wrong. Please, try again.";
const no_video_found$5 = "No video found.";
const watch_playlist_on_youtube$5 = "Watch the entire playlist on YouTube";
const search_video$5 = "Search video";
const logout$5 = "Logout";
const admin_panel$5 = "Admin panel";
const my_products$5 = "My products";
const profile$5 = "Profile";
const starting_from$5 = "Starting from";
const address$5 = "Address";
const more_info$5 = "More information";
const click_to_expand$5 = "Click to expand";
const skip$5 = "Skip";
const signup$5 = "Signup";
const loading$5 = "Loading...";
const cookie_policy$5 = "Cookie policy";
const privacy_policy$5 = "Privacy policy";
const warning$5 = "Warning";
const error$5 = "Error";
const no_results$5 = "No results found.";
const read_more$5 = "Read more";
const recaptcha$5 = "Protected by reCAPTCHA";
const captcha$5 = {
  error_generic: "Unable to verify the captcha. Please, reload the page and try again.",
  error_threshold: "It seems you're not a human. Please, wait a few minutes and try again."
};
const feed_not_available$5 = "Feed not available";
const no_elements_found$5 = "No elements found";
const previous_page$5 = "Previous";
const next_page$5 = "Next";
const docenti$5 = "docenti";
const yearly$5 = "Yearly";
const monthly$5 = "Monthly";
const share_on$5 = "Share on";
const share_with_friend$1 = "Share with a friend";
const copy_link$1 = "Copy link";
const copied$1 = "Copied";
const show_more$5 = "Show more...";
const show_less$5 = "Show less";
const generic_error$1 = "An error occurred while loading this area.";
const elapsed_time$1 = {
  seconds_one: "1 second ago",
  seconds_other: "{{count}} seconds ago",
  minutes_one: "1 minute ago",
  minutes_other: "{{count}} minutes ago",
  hours_one: "1 hour ago",
  hours_other: "{{count}} hours ago",
  days_one: "1 day ago",
  days_other: "{{count}} days ago",
  months_one: "1 month ago",
  months_other: "{{count}} months ago",
  years_one: "1 year ago",
  years_other: "{{count}} years ago"
};
const video$1 = {
  next: "Next",
  prev: "Previous"
};
const enCore = {
  download: download$5,
  form: form$5,
  pricing: pricing$5,
  yes: yes$5,
  no: no$5,
  next: next$5,
  back: back$5,
  language: language$5,
  preferences: preferences$5,
  go_back: go_back$5,
  open: open$5,
  close: close$5,
  login: login$5,
  login_error: login_error$5,
  no_video_found: no_video_found$5,
  watch_playlist_on_youtube: watch_playlist_on_youtube$5,
  search_video: search_video$5,
  logout: logout$5,
  admin_panel: admin_panel$5,
  my_products: my_products$5,
  profile: profile$5,
  starting_from: starting_from$5,
  address: address$5,
  more_info: more_info$5,
  click_to_expand: click_to_expand$5,
  skip: skip$5,
  signup: signup$5,
  loading: loading$5,
  cookie_policy: cookie_policy$5,
  privacy_policy: privacy_policy$5,
  warning: warning$5,
  error: error$5,
  no_results: no_results$5,
  read_more: read_more$5,
  recaptcha: recaptcha$5,
  captcha: captcha$5,
  feed_not_available: feed_not_available$5,
  no_elements_found: no_elements_found$5,
  previous_page: previous_page$5,
  next_page: next_page$5,
  docenti: docenti$5,
  yearly: yearly$5,
  monthly: monthly$5,
  share_on: share_on$5,
  share_with_friend: share_with_friend$1,
  copy_link: copy_link$1,
  copied: copied$1,
  show_more: show_more$5,
  show_less: show_less$5,
  generic_error: generic_error$1,
  elapsed_time: elapsed_time$1,
  video: video$1
};
const en$5 = "English";
const it$5 = "Italiano";
const es$5 = "Español";
const fr$5 = "Français";
const de$5 = "Deutsch";
const pt$5 = "Português";
const pl$5 = "Polskie";
const ru$5 = "Русский";
const cs$5 = "Čeština";
const hu$5 = "Magyar";
const enLanguages = {
  en: en$5,
  "en-GB": "English",
  it: it$5,
  "it-IT": "Italiano",
  es: es$5,
  "es-ES": "Español",
  fr: fr$5,
  "fr-FR": "Français",
  de: de$5,
  "de-DE": "Deutsch",
  pt: pt$5,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$5,
  "pl-PL": "Polskie",
  ru: ru$5,
  "ru-RU": "Русский",
  cs: cs$5,
  "cs-CZ": "Čeština",
  hu: hu$5,
  "hu-HU": "Magyar"
};
const download$4 = {
  wait_download: "<0>La descarga debería comenzar en unos segundos. Si no se inicia automáticamente, <1>haga click aquí</1> para descargar el archivo.</0>"
};
const form$4 = {
  required: "Este campo es obligatorio",
  minLength: "Escriba al menos {{num}} caracteres",
  maxLength: "Se permite un máximo de {{num}} caracteres",
  pattern: "El valor no es válido",
  min: "Valor mínimo permitido: {{num}}",
  max: "Valor máximo permitido: {{num}}",
  add_file: "Añadir fichero",
  too_many_files: "Demasiados archivos",
  too_few_files: "Demasiado pocos archivos",
  file_too_big: "La selección de archivos es demasiado grande",
  invalid_file: "La selección de archivos no es válida",
  max_files: "Número máximo de archivos",
  min_files: "Número mínimo de archivos",
  types_allowed: "Archivos permitidos",
  max_weight: "Tamaño máximo de archivo",
  submit_file_is_uploading: "Debe esperar a que la carga finalice antes de enviar el formulario.",
  upload_error: "Algo ha ido mal durante la carga del archivo. Por favor, inténtelo de nuevo.",
  submit_validation_error: "Algo ha ido mal durante el envío del formulario. Por favor, compruebe sus datos y pruebe de nuevo.",
  submit_error: "Algo ha ido mal durante el envío del formulario. Si el problema persiste, por favor, póngase en contacto con nosotros.",
  privacy: "He leído y aceptado la <1><0>Política de Privacidad</0></1>",
  terms: "He leído y aceptado los <1><0>términos y condiciones<</0></1> ",
  login_prefill: "<0>Inicie sesión</0> para rellenar automáticamente sus datos en el formulario.",
  signup_suggestion: "<0>¡Regístrese ahora</0> para rellenar previamente sus datos en nuestros formularios!",
  captcha_not_resolved: "Tienes que confirmar que eres una persona antes de enviar tu formulario.",
  invalid_phone_prefix: "Prefijo no válido",
  invalid_email: "Dirección de correo electrónico no válida",
  select: {
    create: "{userInput}",
    loading: "Cargando ...",
    no_options: "No se han encuentrado opciones"
  },
  invalid_phone: "El número telefónico es inválido"
};
const pricing$4 = {
  recommended: "Recomendado",
  discounted_to: "La promoción finaliza el {{date}}",
  per_month: "mes",
  per_year: "año",
  free: "GRATIS",
  badge_new: "nuevo",
  price_not_available: "Precio no disponible temporalmente",
  btn_buy_now: "Comprar ahora",
  badge_video: "vídeo",
  incremental_version: "Todo lo ofrecido en la versión anterior y más:",
  prices_starting_from: "Los precios comienzan en"
};
const yes$4 = "si";
const no$4 = "no";
const next$4 = "Siguiente";
const back$4 = "Anterior";
const language$4 = "Lenguaje";
const preferences$4 = "Preferencias";
const go_back$4 = "Volver atrás";
const open$4 = "Abrir";
const close$4 = "Cerrar";
const login$4 = "Iniciar sesión";
const login_error$4 = "Algo fue mal. Por favor, inténtelo de nuevo.";
const no_video_found$4 = "No se ha encontrado ningún vídeo.";
const watch_playlist_on_youtube$4 = "Vea la lista de vídeos entera en YouTube";
const search_video$4 = "Burcar vídeo";
const logout$4 = "Salir";
const admin_panel$4 = "Panel de administración";
const my_products$4 = "Mis productos";
const profile$4 = "Perfil";
const starting_from$4 = "Empezar desde";
const address$4 = "Dirección";
const more_info$4 = "Más información";
const click_to_expand$4 = "Click para expandir";
const skip$4 = "Omitir";
const signup$4 = "Registrarse";
const loading$4 = "Cargando";
const cookie_policy$4 = "Politica de Cookies";
const privacy_policy$4 = "Política de privacidad";
const warning$4 = "Advertencia";
const error$4 = "Error";
const no_results$4 = "No se han encontrado resultados.";
const read_more$4 = "Leer más";
const recaptcha$4 = "Protegida por reCAPTCHA";
const captcha$4 = {
  error_generic: "No se puede verificar el captcha. Por favor, recarga la página y vuelve a intentarlo.",
  error_threshold: "Parece que no eres un humano. Por favor, espere unos minutos e intente de nuevo."
};
const feed_not_available$4 = "Feed no disponible";
const no_elements_found$4 = "No se encontraron elementos";
const previous_page$4 = "Previa";
const next_page$4 = "Próxima";
const docenti$4 = "docenti";
const yearly$4 = "Anual";
const monthly$4 = "Mensual";
const share_on$4 = "Compartir en";
const show_more$4 = "Mostrar más...";
const show_less$4 = "Muestra menos";
const esCore = {
  download: download$4,
  form: form$4,
  pricing: pricing$4,
  yes: yes$4,
  no: no$4,
  next: next$4,
  back: back$4,
  language: language$4,
  preferences: preferences$4,
  go_back: go_back$4,
  open: open$4,
  close: close$4,
  login: login$4,
  login_error: login_error$4,
  no_video_found: no_video_found$4,
  watch_playlist_on_youtube: watch_playlist_on_youtube$4,
  search_video: search_video$4,
  logout: logout$4,
  admin_panel: admin_panel$4,
  my_products: my_products$4,
  profile: profile$4,
  starting_from: starting_from$4,
  address: address$4,
  more_info: more_info$4,
  click_to_expand: click_to_expand$4,
  skip: skip$4,
  signup: signup$4,
  loading: loading$4,
  cookie_policy: cookie_policy$4,
  privacy_policy: privacy_policy$4,
  warning: warning$4,
  error: error$4,
  no_results: no_results$4,
  read_more: read_more$4,
  recaptcha: recaptcha$4,
  captcha: captcha$4,
  feed_not_available: feed_not_available$4,
  no_elements_found: no_elements_found$4,
  previous_page: previous_page$4,
  next_page: next_page$4,
  docenti: docenti$4,
  yearly: yearly$4,
  monthly: monthly$4,
  share_on: share_on$4,
  show_more: show_more$4,
  show_less: show_less$4
};
const en$4 = "English";
const it$4 = "Italiano";
const es$4 = "Español";
const fr$4 = "Français";
const de$4 = "Deutsch";
const pt$4 = "Português";
const pl$4 = "Polskie";
const ru$4 = "Русский";
const cs$4 = "Čeština";
const hu$4 = "Magyar";
const esLanguages = {
  en: en$4,
  "en-GB": "English",
  it: it$4,
  "it-IT": "Italiano",
  es: es$4,
  "es-ES": "Español",
  fr: fr$4,
  "fr-FR": "Français",
  de: de$4,
  "de-DE": "Deutsch",
  pt: pt$4,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$4,
  "pl-PL": "Polskie",
  ru: ru$4,
  "ru-RU": "Русский",
  cs: cs$4,
  "cs-CZ": "Čeština",
  hu: hu$4,
  "hu-HU": "Magyar"
};
const download$3 = {
  wait_download: "<0> Le téléchargement devrait commencer dans quelques secondes. S'il ne démarre pas automatiquement, <1> cliquez ici </1> pour télécharger le fichier. </0>"
};
const form$3 = {
  required: "Ce champ est requis",
  minLength: "Saisissez au moins {{num}} caractères",
  maxLength: "Max {{num}} caractères sont autorisés",
  pattern: "La valeur n'est pas valide",
  min: "Valeur minimale autorisée: {{num}}",
  max: "Valeur maximale autorisée: {{num}}",
  add_file: "Ajouter le fichier",
  too_many_files: "Trop de fichiers",
  too_few_files: "Trop peu de fichiers",
  file_too_big: "Le fichier sélectionné est trop grande",
  invalid_file: "Le fichier sélectionné n'est pas valide",
  max_files: "Nombre maximum de fichiers",
  min_files: "Nombre minimum de fichiers",
  types_allowed: "Fichiers autorisés",
  max_weight: "Taille maximale du fichier",
  submit_file_is_uploading: "Vous devez attendre la fin du téléchargement avant de soumettre votre formulaire.",
  upload_error: "Une erreur s'est produite lors du téléchargement de votre fichier. Veuillez réessayer.",
  submit_validation_error: "Une erreur s'est produite lors de la soumission de votre formulaire. Veuillez vérifier vos données et réessayer.",
  submit_error: "Une erreur s'est produite lors de la soumission de votre formulaire. Si le problème persiste, veuillez nous contacter.",
  privacy: "J'ai lu et j'accepte la <1><0>Politique de confidentialité</0></1>",
  terms: "J'ai lu et j'accepte les <1>termes et conditions</1>.",
  login_prefill: "<0>Connectez-vous</0> pour renseigner automatiquement vos informations dans le formulaire.",
  signup_suggestion: "<0>Inscrivez-vous dès maintenant</0> pour pré-remplir vos données dans nos formulaires!",
  captcha_not_resolved: "Vous devez confirmer que vous êtes un être humain avant de soumettre votre formulaire.",
  invalid_phone_prefix: "Préfixe non valide",
  invalid_email: "Adresse email non valide",
  select: {
    create: "{userInput}",
    loading: "Chargement...",
    no_options: "Aucune option trouvée"
  },
  invalid_phone: "Le numéro de téléphone est invalide"
};
const pricing$3 = {
  recommended: "conseillé",
  discounted_to: "La promotion se termine le {{date}}",
  per_month: "Mois",
  per_year: "Année",
  free: "FREE",
  badge_new: "new",
  price_not_available: "Prix ​​temporairement indisponible",
  btn_buy_now: "Acheter maintenant",
  badge_video: "video",
  incremental_version: "Tout ce qui est offert dans la version précédente et plus :",
  prices_starting_from: "Tarifs à partir de"
};
const yes$3 = "Oui";
const no$3 = "No";
const next$3 = "Suivant";
const back$3 = "Arrière";
const language$3 = "Langue";
const preferences$3 = "Préférences";
const go_back$3 = "Retourner";
const open$3 = "Ouvrir";
const close$3 = "Fermer";
const login$3 = "Login";
const login_error$3 = "Quelque chose s'est mal passé. Veuillez réessayer.";
const no_video_found$3 = "Aucune vidéo trouvée.";
const watch_playlist_on_youtube$3 = "Regardez toute la playlist sur YouTube";
const search_video$3 = "Rechercher une vidéo";
const logout$3 = "Logout";
const admin_panel$3 = "Admin panneau";
const my_products$3 = "Mes produits";
const profile$3 = "Profil";
const starting_from$3 = "Commençant par";
const address$3 = "Adresse";
const more_info$3 = "Plus d'informations";
const click_to_expand$3 = "Cliquez pour agrandir";
const skip$3 = "Sauter";
const signup$3 = "S'inscrire";
const loading$3 = "Chargement...";
const cookie_policy$3 = "Politique relative aux cookies";
const privacy_policy$3 = "Politique de confidentialité";
const warning$3 = "Avertissement";
const error$3 = "Erreur";
const no_results$3 = "Aucun résultat trouvé.";
const read_more$3 = "En savoir plus";
const recaptcha$3 = "Protégé par reCAPTCHA";
const captcha$3 = {
  error_generic: "Impossible de vérifier le captcha. Veuillez recharger la page et réessayer.",
  error_threshold: "Il semble que tu n'es pas un humain. S'il vous plait attendez quelques minutes et réessayez."
};
const feed_not_available$3 = "Flux non disponible";
const no_elements_found$3 = "Aucun élément trouvé";
const previous_page$3 = "Précédent";
const next_page$3 = "Suivant";
const docenti$3 = "docenti";
const yearly$3 = "Annuel";
const monthly$3 = "Mensuel";
const share_on$3 = "Partager sur";
const show_more$3 = "Montre plus...";
const show_less$3 = "Montrer moins";
const frCore = {
  download: download$3,
  form: form$3,
  pricing: pricing$3,
  yes: yes$3,
  no: no$3,
  next: next$3,
  back: back$3,
  language: language$3,
  preferences: preferences$3,
  go_back: go_back$3,
  open: open$3,
  close: close$3,
  login: login$3,
  login_error: login_error$3,
  no_video_found: no_video_found$3,
  watch_playlist_on_youtube: watch_playlist_on_youtube$3,
  search_video: search_video$3,
  logout: logout$3,
  admin_panel: admin_panel$3,
  my_products: my_products$3,
  profile: profile$3,
  starting_from: starting_from$3,
  address: address$3,
  more_info: more_info$3,
  click_to_expand: click_to_expand$3,
  skip: skip$3,
  signup: signup$3,
  loading: loading$3,
  cookie_policy: cookie_policy$3,
  privacy_policy: privacy_policy$3,
  warning: warning$3,
  error: error$3,
  no_results: no_results$3,
  read_more: read_more$3,
  recaptcha: recaptcha$3,
  captcha: captcha$3,
  feed_not_available: feed_not_available$3,
  no_elements_found: no_elements_found$3,
  previous_page: previous_page$3,
  next_page: next_page$3,
  docenti: docenti$3,
  yearly: yearly$3,
  monthly: monthly$3,
  share_on: share_on$3,
  show_more: show_more$3,
  show_less: show_less$3
};
const en$3 = "English";
const it$3 = "Italiano";
const es$3 = "Español";
const fr$3 = "Français";
const de$3 = "Deutsch";
const pt$3 = "Português";
const pl$3 = "Polskie";
const ru$3 = "Русский";
const cs$3 = "Čeština";
const hu$3 = "Magyar";
const frLanguages = {
  en: en$3,
  "en-GB": "English",
  it: it$3,
  "it-IT": "Italiano",
  es: es$3,
  "es-ES": "Español",
  fr: fr$3,
  "fr-FR": "Français",
  de: de$3,
  "de-DE": "Deutsch",
  pt: pt$3,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$3,
  "pl-PL": "Polskie",
  ru: ru$3,
  "ru-RU": "Русский",
  cs: cs$3,
  "cs-CZ": "Čeština",
  hu: hu$3,
  "hu-HU": "Magyar"
};
const download$2 = {
  wait_download: "<0>A letöltés másodperceken belül elindul. Ha mégsem indul el, <1>kattints ide</1> a fájl letöltéséhez.</0>"
};
const form$2 = {
  required: "Ez a mező kötelező",
  minLength: "Írj be legalább {{num}} karaktert",
  maxLength: "A maximális karakterhossz {{num}}",
  pattern: "Érvénytelen érték",
  min: "Minimális érték: {{num}}",
  max: "Maximális érték: {{num}}",
  add_file: "Fájl hozzáadása",
  too_many_files: "Túl sok fájl",
  too_few_files: "Túl kevés fájl",
  file_too_big: "A kiválasztott fájl túl nagy",
  invalid_file: "A kiválasztott fájl érvénytelen",
  max_files: "Fájlok maximális száma",
  min_files: "Fájlok minimális száma",
  types_allowed: "Engedélyezett fájlok",
  max_weight: "Maximális fájl méret",
  submit_file_is_uploading: "Meg kell várnod a fájl feltöltését ahhoz, hogy elmenthesd az űrlapot.",
  upload_error: "Valamilyen hiba történt a fájl feltöltése során. Kérlek próbáld meg később.",
  submit_validation_error: "Valamilyen hiba történt az űrlap mentése közben. Kérlek ellenőrizd az adatokat és próbáld meg később.",
  submit_error: "Valamilyen hiba történt az űrlap mentése közben. Ha a probléma állandósul, kérlek lépj velünk kapcsolatba.",
  privacy: "Elolvastam és egyetértek az <1><0>adatvédelmi irányelvekkel</0></1>",
  terms: "Elolvastam és elfogadom az <1>általános szerződési feltételeket</1>",
  login_prefill: "<0>Lépj be</0>, hogy automatikusan kitöltődjön az űrlap a saját adataiddal.",
  signup_suggestion: "<0>Regisztrálj be</0>, hogy előre kitöltsük az adataidat az űr",
  captcha_not_resolved: "Meg kell bizonyosodnunk róla az űrlap elküldése előtt, hogy valódi ember vagy-e",
  invalid_phone_prefix: "Érvénytelen előtag",
  invalid_email: "Érvénytelen e-mail cím",
  select: {
    create: "{userInput}",
    loading: "Betöltés...",
    no_options: "Nem található opciók"
  },
  invalid_phone: "A telefonszám érvénytelen"
};
const pricing$2 = {
  recommended: "Ajánlott",
  discounted_to: "Promóció vége {{date}}",
  per_month: "hónap",
  per_year: "év",
  free: "INGYENES",
  badge_new: "új",
  price_not_available: "Az ár ideiglenesen nem elérhető",
  btn_buy_now: "Vedd meg most!",
  badge_video: "videó",
  incremental_version: "Minden, amit az előző verzió tartalmaz, plusz a következők:",
  prices_starting_from: "Árak -tól"
};
const yes$2 = "igen";
const no$2 = "nem";
const next$2 = "Következő";
const back$2 = "Vissza";
const language$2 = "Nyelv";
const preferences$2 = "Preferenciák";
const go_back$2 = "Vissza";
const open$2 = "Megnyitás";
const close$2 = "Bezárás";
const login$2 = "Bejelentkezés";
const login_error$2 = "Valamilye probléma történt. Kérlek próbáld újra később!";
const no_video_found$2 = "Nem található videó.";
const watch_playlist_on_youtube$2 = "Nézd meg a teljes lejátszási listát a YouTube-on";
const search_video$2 = "Videó keresése";
const logout$2 = "Kijelentkezés";
const admin_panel$2 = "Admin panel";
const my_products$2 = "Saját termékeim";
const profile$2 = "Profil";
const starting_from$2 = "Kezdve";
const address$2 = "Cím";
const more_info$2 = "További információk";
const click_to_expand$2 = "Kattints a lenyitáshoz";
const skip$2 = "Kihagyás";
const signup$2 = "Regisztráció";
const loading$2 = "Betöltés...";
const cookie_policy$2 = "Cookie szabályzat";
const privacy_policy$2 = "Adatvédelmi irányelvek";
const warning$2 = "Figyelem";
const error$2 = "Hiba";
const no_results$2 = "Nincs találat.";
const read_more$2 = "Read more";
const recaptcha$2 = "ReCAPTCHA védi";
const captcha$2 = {
  error_generic: "A captcha nem ellenőrizhető. Kérjük, töltse be újra az oldalt, és próbálja újra.",
  error_threshold: "Úgy tűnik, nem vagy ember. Kérjük, várjon néhány percet, és próbálja újra."
};
const feed_not_available$2 = "A hírcsatorna nem elérhető";
const no_elements_found$2 = "Nem található elemek";
const previous_page$2 = "Előző";
const next_page$2 = "Következő";
const docenti$2 = "docenti";
const yearly$2 = "Évi";
const monthly$2 = "Havi";
const share_on$2 = "Oszd meg";
const show_more$2 = "Mutass többet...";
const show_less$2 = "Mutass kevesebbet";
const huCore = {
  download: download$2,
  form: form$2,
  pricing: pricing$2,
  yes: yes$2,
  no: no$2,
  next: next$2,
  back: back$2,
  language: language$2,
  preferences: preferences$2,
  go_back: go_back$2,
  open: open$2,
  close: close$2,
  login: login$2,
  login_error: login_error$2,
  no_video_found: no_video_found$2,
  watch_playlist_on_youtube: watch_playlist_on_youtube$2,
  search_video: search_video$2,
  logout: logout$2,
  admin_panel: admin_panel$2,
  my_products: my_products$2,
  profile: profile$2,
  starting_from: starting_from$2,
  address: address$2,
  more_info: more_info$2,
  click_to_expand: click_to_expand$2,
  skip: skip$2,
  signup: signup$2,
  loading: loading$2,
  cookie_policy: cookie_policy$2,
  privacy_policy: privacy_policy$2,
  warning: warning$2,
  error: error$2,
  no_results: no_results$2,
  read_more: read_more$2,
  recaptcha: recaptcha$2,
  captcha: captcha$2,
  feed_not_available: feed_not_available$2,
  no_elements_found: no_elements_found$2,
  previous_page: previous_page$2,
  next_page: next_page$2,
  docenti: docenti$2,
  yearly: yearly$2,
  monthly: monthly$2,
  share_on: share_on$2,
  show_more: show_more$2,
  show_less: show_less$2
};
const en$2 = "English";
const it$2 = "Italiano";
const es$2 = "Español";
const fr$2 = "Français";
const de$2 = "Deutsch";
const pt$2 = "Português";
const pl$2 = "Polskie";
const ru$2 = "Русский";
const cs$2 = "Čeština";
const hu$2 = "Magyar";
const huLanguages = {
  en: en$2,
  "en-GB": "English",
  it: it$2,
  "it-IT": "Italiano",
  es: es$2,
  "es-ES": "Español",
  fr: fr$2,
  "fr-FR": "Français",
  de: de$2,
  "de-DE": "Deutsch",
  pt: pt$2,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$2,
  "pl-PL": "Polskie",
  ru: ru$2,
  "ru-RU": "Русский",
  cs: cs$2,
  "cs-CZ": "Čeština",
  hu: hu$2,
  "hu-HU": "Magyar"
};
const download$1 = {
  wait_download: "<0>Il download dovrebbe partire a momenti. Se non partisse automaticamente, <1>clicca qui</1> per scaricare il file.</0>"
};
const form$1 = {
  required: "Questo campo è obbligatorio",
  minLength: "Inserisci almeno {{num}} caratteri",
  maxLength: "Inserisci al massimo {{num}} caratteri",
  pattern: "Il valore inserito non è valido",
  min: "Inserisci un valore superiore a {{num}}",
  max: "Inserisci un valore inferiore a {{num}}",
  add_file: "Sfoglia",
  too_many_files: "Hai selezionato troppi file",
  too_few_files: "Hai selezionato troppi pochi file",
  file_too_big: "Il file selezionato è troppo grande",
  invalid_file: "Il file selezionato non è valido",
  max_files: "Numero massimo di file",
  min_files: "Numero minimo di file",
  types_allowed: "File permessi",
  max_weight: "Dimensione massima file",
  submit_file_is_uploading: "Devi attendere che l'upload dei file sia terminato prima di procedere all'invio della form",
  upload_error: "Si è verificato un errore durante l'upload del file. Ti preghiamo di riprovare.",
  submit_validation_error: "Si è verificato un errore durante l'invio della form. Ti preghiamo di controllare i dati inseriti e riprovare.",
  submit_error: "Si è verificato un errore durante l'invio della form. Ti preghiamo di contattarci se il problema persiste.",
  privacy: "Ho letto e accettato la <1><0>Privacy Policy</0></1>",
  terms: "Ho letto e accettato i <1>termini e condizioni</1>.",
  login_prefill: "<0>Effettua l'accesso</0> per riempire automaticamente i campi con i tuoi dati.",
  signup_suggestion: "<0>Registrati ora</0> per compilare automaticamente i campi delle nostre form!",
  captcha_not_resolved: "Per procedere all'invio della form, è necessario verificare di essere umano.",
  invalid_phone_prefix: "Prefisso non valido",
  invalid_email: "Indirizzo email non valido",
  doesnt_match: "Il valori inseriti non coincidono",
  select: {
    create: "{userInput}",
    loading: "Caricamento...",
    no_options: "Nessun opzione trovata"
  },
  invalid_phone: "Il numero di telefono non è valido"
};
const pricing$1 = {
  recommended: "Consigliato",
  discounted_to: "Offerta valida fino al {{date}}",
  per_month: "mese",
  per_year: "anno",
  per_workstation_zero: "a postazione",
  per_workstation_one: "a postazione",
  per_workstation_other: "a postazione (minimo {{count}})",
  license_duration_months_one: "1 mese",
  license_duration_months_other: "{{count}} mesi",
  license_duration_years_one: "1 anno",
  license_duration_years_other: "{{count}} anni",
  license_duration: "Durata licenza",
  license_duration_infinite: "Licenza perpetua",
  license_already_active: "Attivo",
  license_already_active_until: "Attivo fino al {{date}}",
  free: "GRATIS",
  badge_new: "new",
  price_not_available: "Prezzo momentaneamente non disponibile",
  btn_buy_now: "Acquista",
  badge_video: "video",
  incremental_version: "Tutto quello offerto con {{prevProductName}} e in più:",
  prices_starting_from: "Prezzi a partire da",
  vat: "IVA"
};
const yes$1 = "si";
const no$1 = "no";
const next$1 = "Avanti";
const back$1 = "Indietro";
const language$1 = "Lingua";
const preferences$1 = "Preferenze";
const go_back$1 = "Torna indietro";
const open$1 = "Apri";
const close$1 = "Chiudi";
const login$1 = "Accedi";
const login_error$1 = "Si è verificato un errore. Ti preghiamo di riprovare.";
const no_video_found$1 = "Nessun video trovato.";
const watch_playlist_on_youtube$1 = "Guarda tutti i video su YouTube";
const search_video$1 = "Ricerca video";
const logout$1 = "Esci";
const admin_panel$1 = "Pannello admin";
const my_products$1 = "I miei prodotti";
const profile$1 = "Profilo";
const starting_from$1 = "A partire da";
const address$1 = "Indirizzo";
const more_info$1 = "Maggiori informazioni";
const click_to_expand$1 = "Clicca per espandere";
const skip$1 = "Salta";
const signup$1 = "Registrati";
const loading$1 = "Caricamento...";
const cookie_policy$1 = "Cookie policy";
const privacy_policy$1 = "Privacy policy";
const warning$1 = "Attenzione";
const error$1 = "Errore";
const no_results$1 = "Nessun risultato trovato.";
const read_more$1 = "Leggi di più";
const recaptcha$1 = "Protetto da reCAPTCHA";
const captcha$1 = {
  error_generic: "Impossibile verificare il captcha. Ti preghiamo di ricaricare la pagina e riprovare.",
  error_threshold: "Sembrerebbe che tu non sia umano. Ti preghiamo di attendere qualche minuto e riprovare."
};
const feed_not_available$1 = "Nessuna news disponibile";
const no_elements_found$1 = "Nessun elemento trovato";
const previous_page$1 = "Precedente";
const next_page$1 = "Successivo";
const docenti$1 = "Docenti";
const yearly$1 = "Annuale";
const monthly$1 = "Mensile";
const share_on$1 = "Condividi con";
const share_with_friend = "Condividi con un amico";
const copy_link = "Copia link";
const copied = "Copiato";
const show_more$1 = "Mostra di più...";
const show_less$1 = "Mostra meno";
const generic_error = "Si è verificato un errore durante il caricamento di quest'area.";
const elapsed_time = {
  seconds_one: "1 secondo fa",
  seconds_other: "{{count}} secondo fa",
  minutes_one: "1 minuto fa",
  minutes_other: "{{count}} minuti fa",
  hours_one: "1 ora fa",
  hours_other: "{{count}} ore fa",
  days_one: "1 giorno fa",
  days_other: "{{count}} giorni fa",
  months_one: "1 mese fa",
  months_other: "{{count}} mesi fa",
  years_one: "1 anno fa",
  years_other: "{{count}} anni fa"
};
const feed = {
  no_items_found: "Nessun elemento trovato"
};
const video = {
  next: "Prossimo",
  prev: "Precedente",
  no_videos_found: "Nessun video trovato",
  watch_on_yt: "Guarda su YouTube",
  watch_video: "Guarda il video"
};
const pagination = {
  next: "Pagina successiva"
};
const search = {
  clear: "Reset ricerca",
  placeholder: "Cerca"
};
const reviews = {
  bad: "Cattivo",
  poor: "Povero",
  average: "Nella media",
  great: "Molto buono",
  excellent: "Eccellente",
  verified_by_trustpilot: "Sulla base di {{count}} recensioni verificate su Trustpilot",
  read_more: "Leggi di più",
  read_less: "Leggi di meno",
  review_date: "Recensione del {{date}}",
  average_out_of: "{{average}} su {{max}}",
  statistics_label_one: "1 stella",
  statistics_label_other: "{{count}} stelle",
  filterLabels: {
    province: "Provincia",
    sortBy: "Ordina per",
    rating: "Voto"
  }
};
const itCore = {
  download: download$1,
  form: form$1,
  pricing: pricing$1,
  yes: yes$1,
  no: no$1,
  next: next$1,
  back: back$1,
  language: language$1,
  preferences: preferences$1,
  go_back: go_back$1,
  open: open$1,
  close: close$1,
  login: login$1,
  login_error: login_error$1,
  no_video_found: no_video_found$1,
  watch_playlist_on_youtube: watch_playlist_on_youtube$1,
  search_video: search_video$1,
  logout: logout$1,
  admin_panel: admin_panel$1,
  my_products: my_products$1,
  profile: profile$1,
  starting_from: starting_from$1,
  address: address$1,
  more_info: more_info$1,
  click_to_expand: click_to_expand$1,
  skip: skip$1,
  signup: signup$1,
  loading: loading$1,
  cookie_policy: cookie_policy$1,
  privacy_policy: privacy_policy$1,
  warning: warning$1,
  error: error$1,
  no_results: no_results$1,
  read_more: read_more$1,
  recaptcha: recaptcha$1,
  captcha: captcha$1,
  feed_not_available: feed_not_available$1,
  no_elements_found: no_elements_found$1,
  previous_page: previous_page$1,
  next_page: next_page$1,
  docenti: docenti$1,
  yearly: yearly$1,
  monthly: monthly$1,
  share_on: share_on$1,
  share_with_friend,
  copy_link,
  copied,
  show_more: show_more$1,
  show_less: show_less$1,
  generic_error,
  elapsed_time,
  feed,
  video,
  pagination,
  search,
  reviews
};
const en$1 = "English";
const it$1 = "Italiano";
const es$1 = "Español";
const fr$1 = "Français";
const de$1 = "Deutsch";
const pt$1 = "Português";
const pl$1 = "Polskie";
const ru$1 = "Русский";
const cs$1 = "Čeština";
const hu$1 = "Magyar";
const itLanguages = {
  en: en$1,
  "en-GB": "English",
  it: it$1,
  "it-IT": "Italiano",
  es: es$1,
  "es-ES": "Español",
  fr: fr$1,
  "fr-FR": "Français",
  de: de$1,
  "de-DE": "Deutsch",
  pt: pt$1,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$1,
  "pl-PL": "Polskie",
  ru: ru$1,
  "ru-RU": "Русский",
  cs: cs$1,
  "cs-CZ": "Čeština",
  hu: hu$1,
  "hu-HU": "Magyar"
};
const download = {
  wait_download: "<0>O download deve começar em alguns segundos. Se não iniciar automaticamente, <1>clique aqui</1> para baixar o arquivo.</0>"
};
const form = {
  required: "Este campo é obrigatório",
  minLength: "Digite pelo menos {{num}} caracteres",
  maxLength: "Máximo de {{num}} caracteres são permitidos",
  pattern: "O valor não é válido",
  min: "Valor mínimo permitido: {{num}}",
  max: "Valor máximo permitido: {{num}}",
  add_file: "Adicionar ficheiro",
  too_many_files: "Muitos arquivos",
  too_few_files: "Poucos arquivos",
  file_too_big: "O arquivo selecionado é muito grande",
  invalid_file: "O arquivo selecionado não é válido",
  max_files: "Número máximo de arquivos",
  min_files: "Número mínimo de arquivos",
  types_allowed: "Arquivos permitidos",
  max_weight: "Tamanho máximo do arquivo",
  submit_file_is_uploading: "Precisa aguardar a conclusão do upload antes de enviar o seu formulário.",
  upload_error: "Algo deu errado ao fazer o upload do seu arquivo. Por favor, tente novamente.",
  submit_validation_error: "Algo deu errado ao enviar seu formulário. Por favor, verifique os seus dados e tente novamente.",
  submit_error: "Algo deu errado ao enviar o seu formulário. Se o problema persistir, entre em contato conosco.",
  privacy: "Li e concordei com a <1><0>Política de Privacidade</0></1>",
  terms: "Li e concordei com os <1>termos e condições</1>",
  login_prefill: "<0>Faça login</0> para preencher automaticamente as suas informações no formulário.",
  signup_suggestion: "<0>Inscreva-se agora</0> para preencher previamente os seus dados em nossos formulários!",
  captcha_not_resolved: "Você precisa confirmar que é humano antes de enviar seu formulário.",
  invalid_phone_prefix: "Prefixo inválido",
  invalid_email: "Endereço de email inválido",
  select: {
    create: "{userInput}",
    loading: "Carregando...",
    no_options: "Nenhuma opção encontrada"
  },
  invalid_phone: "O número de telefone é inválido"
};
const pricing = {
  recommended: "Recomendado",
  discounted_to: "A promoção termina em {{date}}",
  per_month: "Mês",
  per_year: "Ano",
  free: "GRÁTIS",
  badge_new: "novo",
  price_not_available: "Preço temporariamente indisponível",
  btn_buy_now: "Compre agora",
  badge_video: "video",
  incremental_version: "Tudo oferecido na versão anterior e mais:",
  prices_starting_from: "Preços a partir de"
};
const yes = "sim";
const no = "não";
const next = "Próximo\n";
const back = "Voltar";
const language = "Idioma";
const preferences = "Preferências";
const go_back = "Volte atrás";
const open = "Abrir";
const close = "Fechar";
const login = "Registrar";
const login_error = "Algo deu errado. Por favor, tente novamente.";
const no_video_found = "Nenhum vídeo encontrado.";
const watch_playlist_on_youtube = "Veja a playlist inteira no YouTube";
const search_video = "Procurar vídeo";
const logout = "Sair";
const admin_panel = "Painel de administração";
const my_products = "Os meus produtos";
const profile = "Perfil";
const starting_from = "A partir de";
const address = "Endereço";
const more_info = "Mais informações";
const click_to_expand = "Clique para expandir";
const skip = "Pular";
const signup = "Inscrever-se";
const loading = "Carregando...";
const cookie_policy = "Política de cookies";
const privacy_policy = "Política de Privacidade";
const warning = "Aviso";
const error = "Erro";
const no_results = "Nenhum resultado encontrado.";
const read_more = "Consulte Mais informação";
const recaptcha = "Protegido por reCAPTCHA";
const captcha = {
  error_generic: "Não foi possível verificar o captcha. Por favor, recarregue a página e tente denovo.",
  error_threshold: "Parece que você não é humano. Por favor, aguarde alguns minutos e tente novamente."
};
const feed_not_available = "Feed não disponível";
const no_elements_found = "Nenhum elemento encontrado";
const previous_page = "Anterior";
const next_page = "Próximo";
const docenti = "docenti";
const yearly = "Anual";
const monthly = "Por mês";
const share_on = "Compartilhar no";
const show_more = "Mostre mais...";
const show_less = "Mostre menos";
const ptCore = {
  download,
  form,
  pricing,
  yes,
  no,
  next,
  back,
  language,
  preferences,
  go_back,
  open,
  close,
  login,
  login_error,
  no_video_found,
  watch_playlist_on_youtube,
  search_video,
  logout,
  admin_panel,
  my_products,
  profile,
  starting_from,
  address,
  more_info,
  click_to_expand,
  skip,
  signup,
  loading,
  cookie_policy,
  privacy_policy,
  warning,
  error,
  no_results,
  read_more,
  recaptcha,
  captcha,
  feed_not_available,
  no_elements_found,
  previous_page,
  next_page,
  docenti,
  yearly,
  monthly,
  share_on,
  show_more,
  show_less
};
const en = "English";
const it = "Italiano";
const es = "Español";
const fr = "Français";
const de = "Deutsch";
const pt = "Português";
const pl = "Polskie";
const ru = "Русский";
const cs = "Čeština";
const hu = "Magyar";
const ptLanguages = {
  en,
  "en-GB": "English",
  it,
  "it-IT": "Italiano",
  es,
  "es-ES": "Español",
  fr,
  "fr-FR": "Français",
  de,
  "de-DE": "Deutsch",
  pt,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl,
  "pl-PL": "Polskie",
  ru,
  "ru-RU": "Русский",
  cs,
  "cs-CZ": "Čeština",
  hu,
  "hu-HU": "Magyar"
};
var commonjsGlobal$1 = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
function getAugmentedNamespace(n) {
  if (n.__esModule) return n;
  var f = n.default;
  if (typeof f == "function") {
    var a = function a2() {
      if (this instanceof a2) {
        return Reflect.construct(f, arguments, this.constructor);
      }
      return f.apply(this, arguments);
    };
    a.prototype = f.prototype;
  } else a = {};
  Object.defineProperty(a, "__esModule", { value: true });
  Object.keys(n).forEach(function(k) {
    var d = Object.getOwnPropertyDescriptor(n, k);
    Object.defineProperty(a, k, d.get ? d : {
      enumerable: true,
      get: function() {
        return n[k];
      }
    });
  });
  return a;
}
function _typeof(obj) {
  "@babel/helpers - typeof";
  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(obj2) {
    return typeof obj2;
  } : function(obj2) {
    return obj2 && "function" == typeof Symbol && obj2.constructor === Symbol && obj2 !== Symbol.prototype ? "symbol" : typeof obj2;
  }, _typeof(obj);
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = true;
    if ("value" in descriptor) descriptor.writable = true;
    Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
  }
}
function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps);
  if (staticProps) _defineProperties(Constructor, staticProps);
  Object.defineProperty(Constructor, "prototype", {
    writable: false
  });
  return Constructor;
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== void 0) {
    var res = prim.call(input, hint);
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return String(input);
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _classPrivateFieldGet(receiver, privateMap) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get");
  return _classApplyDescriptorGet(receiver, descriptor);
}
function _classPrivateFieldSet(receiver, privateMap, value) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "set");
  _classApplyDescriptorSet(receiver, descriptor, value);
  return value;
}
function _classExtractFieldDescriptor(receiver, privateMap, action) {
  if (!privateMap.has(receiver)) {
    throw new TypeError("attempted to " + action + " private field on non-instance");
  }
  return privateMap.get(receiver);
}
function _classApplyDescriptorGet(receiver, descriptor) {
  if (descriptor.get) {
    return descriptor.get.call(receiver);
  }
  return descriptor.value;
}
function _classApplyDescriptorSet(receiver, descriptor, value) {
  if (descriptor.set) {
    descriptor.set.call(receiver, value);
  } else {
    if (!descriptor.writable) {
      throw new TypeError("attempted to set read only private field");
    }
    descriptor.value = value;
  }
}
function _checkPrivateRedeclaration(obj, privateCollection) {
  if (privateCollection.has(obj)) {
    throw new TypeError("Cannot initialize the same private elements twice on an object");
  }
}
function _classPrivateFieldInitSpec(obj, privateMap, value) {
  _checkPrivateRedeclaration(obj, privateMap);
  privateMap.set(obj, value);
}
var canonicalizeLocaleList = function canonicalizeLocaleList2(locales) {
  if (!locales) return [];
  if (!Array.isArray(locales)) locales = [locales];
  var res = {};
  for (var i = 0; i < locales.length; ++i) {
    var _in$iw$ji$lc;
    var tag = locales[i];
    if (tag && _typeof(tag) === "object") tag = String(tag);
    if (typeof tag !== "string") {
      var msg = "Locales should be strings, ".concat(JSON.stringify(tag), " isn't.");
      throw new TypeError(msg);
    }
    var parts = tag.split("-");
    if (!parts.every(function(subtag) {
      return /[a-z0-9]+/i.test(subtag);
    })) {
      var strTag = JSON.stringify(tag);
      var _msg = "The locale ".concat(strTag, " is not a structurally valid BCP 47 language tag.");
      throw new RangeError(_msg);
    }
    var lc = parts[0].toLowerCase();
    parts[0] = (_in$iw$ji$lc = {
      in: "id",
      iw: "he",
      ji: "yi"
    }[lc]) !== null && _in$iw$ji$lc !== void 0 ? _in$iw$ji$lc : lc;
    res[parts.join("-")] = true;
  }
  return Object.keys(res);
};
function getType(opt) {
  var type = Object.prototype.hasOwnProperty.call(opt, "type") && opt.type;
  if (!type) return "cardinal";
  if (type === "cardinal" || type === "ordinal") return type;
  throw new RangeError("Not a valid plural type: " + JSON.stringify(type));
}
function toNumber(value) {
  switch (_typeof(value)) {
    case "number":
      return value;
    case "bigint":
      throw new TypeError("Cannot convert a BigInt value to a number");
    default:
      return Number(value);
  }
}
function getPluralRules$1(NumberFormat, getSelector3, getCategories3, getRangeSelector3) {
  var findLocale = function findLocale2(locale) {
    do {
      if (getSelector3(locale)) return locale;
      locale = locale.replace(/-?[^-]*$/, "");
    } while (locale);
    return null;
  };
  var resolveLocale = function resolveLocale2(locales) {
    var canonicalLocales = canonicalizeLocaleList(locales);
    for (var i = 0; i < canonicalLocales.length; ++i) {
      var _lc = findLocale(canonicalLocales[i]);
      if (_lc) return _lc;
    }
    var lc = new NumberFormat().resolvedOptions().locale;
    return findLocale(lc);
  };
  var _locale = /* @__PURE__ */ new WeakMap();
  var _range = /* @__PURE__ */ new WeakMap();
  var _select = /* @__PURE__ */ new WeakMap();
  var _type = /* @__PURE__ */ new WeakMap();
  var _nf = /* @__PURE__ */ new WeakMap();
  var PluralRules2 = /* @__PURE__ */ function() {
    function PluralRules3() {
      var locales = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
      var opt = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
      _classCallCheck(this, PluralRules3);
      _classPrivateFieldInitSpec(this, _locale, {
        writable: true,
        value: void 0
      });
      _classPrivateFieldInitSpec(this, _range, {
        writable: true,
        value: void 0
      });
      _classPrivateFieldInitSpec(this, _select, {
        writable: true,
        value: void 0
      });
      _classPrivateFieldInitSpec(this, _type, {
        writable: true,
        value: void 0
      });
      _classPrivateFieldInitSpec(this, _nf, {
        writable: true,
        value: void 0
      });
      _classPrivateFieldSet(this, _locale, resolveLocale(locales));
      _classPrivateFieldSet(this, _select, getSelector3(_classPrivateFieldGet(this, _locale)));
      _classPrivateFieldSet(this, _range, getRangeSelector3(_classPrivateFieldGet(this, _locale)));
      _classPrivateFieldSet(this, _type, getType(opt));
      _classPrivateFieldSet(this, _nf, new NumberFormat("en", opt));
    }
    _createClass(PluralRules3, [{
      key: "resolvedOptions",
      value: function resolvedOptions() {
        var _classPrivateFieldGet2 = _classPrivateFieldGet(this, _nf).resolvedOptions(), minimumIntegerDigits = _classPrivateFieldGet2.minimumIntegerDigits, minimumFractionDigits = _classPrivateFieldGet2.minimumFractionDigits, maximumFractionDigits = _classPrivateFieldGet2.maximumFractionDigits, minimumSignificantDigits = _classPrivateFieldGet2.minimumSignificantDigits, maximumSignificantDigits = _classPrivateFieldGet2.maximumSignificantDigits, roundingPriority = _classPrivateFieldGet2.roundingPriority;
        var opt = {
          locale: _classPrivateFieldGet(this, _locale),
          type: _classPrivateFieldGet(this, _type),
          minimumIntegerDigits,
          minimumFractionDigits,
          maximumFractionDigits
        };
        if (typeof minimumSignificantDigits === "number") {
          opt.minimumSignificantDigits = minimumSignificantDigits;
          opt.maximumSignificantDigits = maximumSignificantDigits;
        }
        opt.pluralCategories = getCategories3(_classPrivateFieldGet(this, _locale), _classPrivateFieldGet(this, _type) === "ordinal").slice(0);
        opt.roundingPriority = roundingPriority || "auto";
        return opt;
      }
    }, {
      key: "select",
      value: function select(number) {
        if (!(this instanceof PluralRules3)) throw new TypeError("select() called on incompatible ".concat(this));
        if (typeof number !== "number") number = Number(number);
        if (!isFinite(number)) return "other";
        var fmt = _classPrivateFieldGet(this, _nf).format(Math.abs(number));
        return _classPrivateFieldGet(this, _select).call(this, fmt, _classPrivateFieldGet(this, _type) === "ordinal");
      }
    }, {
      key: "selectRange",
      value: function selectRange(start, end) {
        if (!(this instanceof PluralRules3)) throw new TypeError("selectRange() called on incompatible ".concat(this));
        if (start === void 0) throw new TypeError("start is undefined");
        if (end === void 0) throw new TypeError("end is undefined");
        var start_ = toNumber(start);
        var end_ = toNumber(end);
        if (!isFinite(start_)) throw new RangeError("start must be finite");
        if (!isFinite(end_)) throw new RangeError("end must be finite");
        return _classPrivateFieldGet(this, _range).call(this, this.select(start_), this.select(end_));
      }
    }], [{
      key: "supportedLocalesOf",
      value: function supportedLocalesOf(locales) {
        return canonicalizeLocaleList(locales).filter(findLocale);
      }
    }]);
    return PluralRules3;
  }();
  if (typeof Symbol !== "undefined" && Symbol.toStringTag) {
    Object.defineProperty(PluralRules2.prototype, Symbol.toStringTag, {
      value: "Intl.PluralRules",
      writable: false,
      configurable: true
    });
  }
  Object.defineProperty(PluralRules2, "prototype", {
    writable: false
  });
  return PluralRules2;
}
const factory = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: getPluralRules$1
}, Symbol.toStringTag, { value: "Module" }));
const require$$0 = /* @__PURE__ */ getAugmentedNamespace(factory);
var getPluralRules = require$$0;
function _interopDefaultLegacy$1(e) {
  return e && typeof e === "object" && "default" in e ? e : { "default": e };
}
function _mergeNamespaces(n, m) {
  m.forEach(function(e) {
    e && typeof e !== "string" && !Array.isArray(e) && Object.keys(e).forEach(function(k) {
      if (k !== "default" && !(k in n)) {
        var d = Object.getOwnPropertyDescriptor(e, k);
        Object.defineProperty(n, k, d.get ? d : {
          enumerable: true,
          get: function() {
            return e[k];
          }
        });
      }
    });
  });
  return Object.freeze(n);
}
var getPluralRules__default = /* @__PURE__ */ _interopDefaultLegacy$1(getPluralRules);
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof commonjsGlobal$1 !== "undefined" ? commonjsGlobal$1 : typeof self !== "undefined" ? self : {};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
var plurals$1 = { exports: {} };
(function(module, exports) {
  var a = function a2(n, ord) {
    if (ord) return "other";
    return n == 1 ? "one" : "other";
  };
  var b = function b2(n, ord) {
    if (ord) return "other";
    return n == 0 || n == 1 ? "one" : "other";
  };
  var c = function c2(n, ord) {
    if (ord) return "other";
    return n >= 0 && n <= 1 ? "one" : "other";
  };
  var d = function d2(n, ord) {
    var s = String(n).split("."), v0 = !s[1];
    if (ord) return "other";
    return n == 1 && v0 ? "one" : "other";
  };
  var e = function e2(n, ord) {
    return "other";
  };
  var f = function f2(n, ord) {
    if (ord) return "other";
    return n == 1 ? "one" : n == 2 ? "two" : "other";
  };
  (function(root, plurals2) {
    Object.defineProperty(plurals2, "__esModule", {
      value: true
    });
    module.exports = plurals2;
  })(commonjsGlobal, {
    af: a,
    ak: b,
    am: c,
    an: a,
    ar: function ar(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2);
      if (ord) return "other";
      return n == 0 ? "zero" : n == 1 ? "one" : n == 2 ? "two" : n100 >= 3 && n100 <= 10 ? "few" : n100 >= 11 && n100 <= 99 ? "many" : "other";
    },
    ars: function ars(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2);
      if (ord) return "other";
      return n == 0 ? "zero" : n == 1 ? "one" : n == 2 ? "two" : n100 >= 3 && n100 <= 10 ? "few" : n100 >= 11 && n100 <= 99 ? "many" : "other";
    },
    as: function as(n, ord) {
      if (ord) return n == 1 || n == 5 || n == 7 || n == 8 || n == 9 || n == 10 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other";
      return n >= 0 && n <= 1 ? "one" : "other";
    },
    asa: a,
    ast: d,
    az: function az(n, ord) {
      var s = String(n).split("."), i = s[0], i10 = i.slice(-1), i100 = i.slice(-2), i1000 = i.slice(-3);
      if (ord) return i10 == 1 || i10 == 2 || i10 == 5 || i10 == 7 || i10 == 8 || i100 == 20 || i100 == 50 || i100 == 70 || i100 == 80 ? "one" : i10 == 3 || i10 == 4 || i1000 == 100 || i1000 == 200 || i1000 == 300 || i1000 == 400 || i1000 == 500 || i1000 == 600 || i1000 == 700 || i1000 == 800 || i1000 == 900 ? "few" : i == 0 || i10 == 6 || i100 == 40 || i100 == 60 || i100 == 90 ? "many" : "other";
      return n == 1 ? "one" : "other";
    },
    bal: function bal(n, ord) {
      return n == 1 ? "one" : "other";
    },
    be: function be(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
      if (ord) return (n10 == 2 || n10 == 3) && n100 != 12 && n100 != 13 ? "few" : "other";
      return n10 == 1 && n100 != 11 ? "one" : n10 >= 2 && n10 <= 4 && (n100 < 12 || n100 > 14) ? "few" : t0 && n10 == 0 || n10 >= 5 && n10 <= 9 || n100 >= 11 && n100 <= 14 ? "many" : "other";
    },
    bem: a,
    bez: a,
    bg: a,
    bho: b,
    bm: e,
    bn: function bn(n, ord) {
      if (ord) return n == 1 || n == 5 || n == 7 || n == 8 || n == 9 || n == 10 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other";
      return n >= 0 && n <= 1 ? "one" : "other";
    },
    bo: e,
    br: function br(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2), n1000000 = t0 && s[0].slice(-6);
      if (ord) return "other";
      return n10 == 1 && n100 != 11 && n100 != 71 && n100 != 91 ? "one" : n10 == 2 && n100 != 12 && n100 != 72 && n100 != 92 ? "two" : (n10 == 3 || n10 == 4 || n10 == 9) && (n100 < 10 || n100 > 19) && (n100 < 70 || n100 > 79) && (n100 < 90 || n100 > 99) ? "few" : n != 0 && t0 && n1000000 == 0 ? "many" : "other";
    },
    brx: a,
    bs: function bs(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2), f10 = f2.slice(-1), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) || f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14) ? "few" : "other";
    },
    ca: function ca(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return n == 1 || n == 3 ? "one" : n == 2 ? "two" : n == 4 ? "few" : "other";
      return n == 1 && v0 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    ce: a,
    ceb: function ceb(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), f10 = f2.slice(-1);
      if (ord) return "other";
      return v0 && (i == 1 || i == 2 || i == 3) || v0 && i10 != 4 && i10 != 6 && i10 != 9 || !v0 && f10 != 4 && f10 != 6 && f10 != 9 ? "one" : "other";
    },
    cgg: a,
    chr: a,
    ckb: a,
    cs: function cs2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1];
      if (ord) return "other";
      return n == 1 && v0 ? "one" : i >= 2 && i <= 4 && v0 ? "few" : !v0 ? "many" : "other";
    },
    cy: function cy(n, ord) {
      if (ord) return n == 0 || n == 7 || n == 8 || n == 9 ? "zero" : n == 1 ? "one" : n == 2 ? "two" : n == 3 || n == 4 ? "few" : n == 5 || n == 6 ? "many" : "other";
      return n == 0 ? "zero" : n == 1 ? "one" : n == 2 ? "two" : n == 3 ? "few" : n == 6 ? "many" : "other";
    },
    da: function da(n, ord) {
      var s = String(n).split("."), i = s[0], t0 = Number(s[0]) == n;
      if (ord) return "other";
      return n == 1 || !t0 && (i == 0 || i == 1) ? "one" : "other";
    },
    de: d,
    doi: c,
    dsb: function dsb(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i100 = i.slice(-2), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i100 == 1 || f100 == 1 ? "one" : v0 && i100 == 2 || f100 == 2 ? "two" : v0 && (i100 == 3 || i100 == 4) || f100 == 3 || f100 == 4 ? "few" : "other";
    },
    dv: a,
    dz: e,
    ee: a,
    el: a,
    en: function en2(n, ord) {
      var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
      if (ord) return n10 == 1 && n100 != 11 ? "one" : n10 == 2 && n100 != 12 ? "two" : n10 == 3 && n100 != 13 ? "few" : "other";
      return n == 1 && v0 ? "one" : "other";
    },
    eo: a,
    es: function es2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return "other";
      return n == 1 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    et: d,
    eu: a,
    fa: c,
    ff: function ff(n, ord) {
      if (ord) return "other";
      return n >= 0 && n < 2 ? "one" : "other";
    },
    fi: d,
    fil: function fil(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), f10 = f2.slice(-1);
      if (ord) return n == 1 ? "one" : "other";
      return v0 && (i == 1 || i == 2 || i == 3) || v0 && i10 != 4 && i10 != 6 && i10 != 9 || !v0 && f10 != 4 && f10 != 6 && f10 != 9 ? "one" : "other";
    },
    fo: a,
    fr: function fr2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return n == 1 ? "one" : "other";
      return n >= 0 && n < 2 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    fur: a,
    fy: d,
    ga: function ga(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return n == 1 ? "one" : "other";
      return n == 1 ? "one" : n == 2 ? "two" : t0 && n >= 3 && n <= 6 ? "few" : t0 && n >= 7 && n <= 10 ? "many" : "other";
    },
    gd: function gd(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return n == 1 || n == 11 ? "one" : n == 2 || n == 12 ? "two" : n == 3 || n == 13 ? "few" : "other";
      return n == 1 || n == 11 ? "one" : n == 2 || n == 12 ? "two" : t0 && n >= 3 && n <= 10 || t0 && n >= 13 && n <= 19 ? "few" : "other";
    },
    gl: d,
    gsw: a,
    gu: function gu(n, ord) {
      if (ord) return n == 1 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other";
      return n >= 0 && n <= 1 ? "one" : "other";
    },
    guw: b,
    gv: function gv(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 ? "one" : v0 && i10 == 2 ? "two" : v0 && (i100 == 0 || i100 == 20 || i100 == 40 || i100 == 60 || i100 == 80) ? "few" : !v0 ? "many" : "other";
    },
    ha: a,
    haw: a,
    he: function he(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1];
      if (ord) return "other";
      return i == 1 && v0 || i == 0 && !v0 ? "one" : i == 2 && v0 ? "two" : "other";
    },
    hi: function hi(n, ord) {
      if (ord) return n == 1 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other";
      return n >= 0 && n <= 1 ? "one" : "other";
    },
    hnj: e,
    hr: function hr(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2), f10 = f2.slice(-1), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) || f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14) ? "few" : "other";
    },
    hsb: function hsb(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i100 = i.slice(-2), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i100 == 1 || f100 == 1 ? "one" : v0 && i100 == 2 || f100 == 2 ? "two" : v0 && (i100 == 3 || i100 == 4) || f100 == 3 || f100 == 4 ? "few" : "other";
    },
    hu: function hu2(n, ord) {
      if (ord) return n == 1 || n == 5 ? "one" : "other";
      return n == 1 ? "one" : "other";
    },
    hy: function hy(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return n >= 0 && n < 2 ? "one" : "other";
    },
    ia: d,
    id: e,
    ig: e,
    ii: e,
    io: d,
    is: function is(n, ord) {
      var s = String(n).split("."), i = s[0], t = (s[1] || "").replace(/0+$/, ""), t0 = Number(s[0]) == n, i10 = i.slice(-1), i100 = i.slice(-2);
      if (ord) return "other";
      return t0 && i10 == 1 && i100 != 11 || t % 10 == 1 && t % 100 != 11 ? "one" : "other";
    },
    it: function it2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return n == 11 || n == 8 || n == 80 || n == 800 ? "many" : "other";
      return n == 1 && v0 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    iu: f,
    ja: e,
    jbo: e,
    jgo: a,
    jmc: a,
    jv: e,
    jw: e,
    ka: function ka(n, ord) {
      var s = String(n).split("."), i = s[0], i100 = i.slice(-2);
      if (ord) return i == 1 ? "one" : i == 0 || i100 >= 2 && i100 <= 20 || i100 == 40 || i100 == 60 || i100 == 80 ? "many" : "other";
      return n == 1 ? "one" : "other";
    },
    kab: function kab(n, ord) {
      if (ord) return "other";
      return n >= 0 && n < 2 ? "one" : "other";
    },
    kaj: a,
    kcg: a,
    kde: e,
    kea: e,
    kk: function kk(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1);
      if (ord) return n10 == 6 || n10 == 9 || t0 && n10 == 0 && n != 0 ? "many" : "other";
      return n == 1 ? "one" : "other";
    },
    kkj: a,
    kl: a,
    km: e,
    kn: c,
    ko: e,
    ks: a,
    ksb: a,
    ksh: function ksh(n, ord) {
      if (ord) return "other";
      return n == 0 ? "zero" : n == 1 ? "one" : "other";
    },
    ku: a,
    kw: function kw(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2), n1000 = t0 && s[0].slice(-3), n100000 = t0 && s[0].slice(-5), n1000000 = t0 && s[0].slice(-6);
      if (ord) return t0 && n >= 1 && n <= 4 || n100 >= 1 && n100 <= 4 || n100 >= 21 && n100 <= 24 || n100 >= 41 && n100 <= 44 || n100 >= 61 && n100 <= 64 || n100 >= 81 && n100 <= 84 ? "one" : n == 5 || n100 == 5 ? "many" : "other";
      return n == 0 ? "zero" : n == 1 ? "one" : n100 == 2 || n100 == 22 || n100 == 42 || n100 == 62 || n100 == 82 || t0 && n1000 == 0 && (n100000 >= 1e3 && n100000 <= 2e4 || n100000 == 4e4 || n100000 == 6e4 || n100000 == 8e4) || n != 0 && n1000000 == 1e5 ? "two" : n100 == 3 || n100 == 23 || n100 == 43 || n100 == 63 || n100 == 83 ? "few" : n != 1 && (n100 == 1 || n100 == 21 || n100 == 41 || n100 == 61 || n100 == 81) ? "many" : "other";
    },
    ky: a,
    lag: function lag(n, ord) {
      var s = String(n).split("."), i = s[0];
      if (ord) return "other";
      return n == 0 ? "zero" : (i == 0 || i == 1) && n != 0 ? "one" : "other";
    },
    lb: a,
    lg: a,
    lij: function lij(n, ord) {
      var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n;
      if (ord) return n == 11 || n == 8 || t0 && n >= 80 && n <= 89 || t0 && n >= 800 && n <= 899 ? "many" : "other";
      return n == 1 && v0 ? "one" : "other";
    },
    lkt: e,
    ln: b,
    lo: function lo(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return "other";
    },
    lt: function lt(n, ord) {
      var s = String(n).split("."), f2 = s[1] || "", t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
      if (ord) return "other";
      return n10 == 1 && (n100 < 11 || n100 > 19) ? "one" : n10 >= 2 && n10 <= 9 && (n100 < 11 || n100 > 19) ? "few" : f2 != 0 ? "many" : "other";
    },
    lv: function lv(n, ord) {
      var s = String(n).split("."), f2 = s[1] || "", v = f2.length, t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2), f100 = f2.slice(-2), f10 = f2.slice(-1);
      if (ord) return "other";
      return t0 && n10 == 0 || n100 >= 11 && n100 <= 19 || v == 2 && f100 >= 11 && f100 <= 19 ? "zero" : n10 == 1 && n100 != 11 || v == 2 && f10 == 1 && f100 != 11 || v != 2 && f10 == 1 ? "one" : "other";
    },
    mas: a,
    mg: b,
    mgo: a,
    mk: function mk(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2), f10 = f2.slice(-1), f100 = f2.slice(-2);
      if (ord) return i10 == 1 && i100 != 11 ? "one" : i10 == 2 && i100 != 12 ? "two" : (i10 == 7 || i10 == 8) && i100 != 17 && i100 != 18 ? "many" : "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : "other";
    },
    ml: a,
    mn: a,
    mo: function mo(n, ord) {
      var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2);
      if (ord) return n == 1 ? "one" : "other";
      return n == 1 && v0 ? "one" : !v0 || n == 0 || n != 1 && n100 >= 1 && n100 <= 19 ? "few" : "other";
    },
    mr: function mr(n, ord) {
      if (ord) return n == 1 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : "other";
      return n == 1 ? "one" : "other";
    },
    ms: function ms(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return "other";
    },
    mt: function mt(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2);
      if (ord) return "other";
      return n == 1 ? "one" : n == 2 ? "two" : n == 0 || n100 >= 3 && n100 <= 10 ? "few" : n100 >= 11 && n100 <= 19 ? "many" : "other";
    },
    my: e,
    nah: a,
    naq: f,
    nb: a,
    nd: a,
    ne: function ne(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return t0 && n >= 1 && n <= 4 ? "one" : "other";
      return n == 1 ? "one" : "other";
    },
    nl: d,
    nn: a,
    nnh: a,
    no: a,
    nqo: e,
    nr: a,
    nso: b,
    ny: a,
    nyn: a,
    om: a,
    or: function or(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return n == 1 || n == 5 || t0 && n >= 7 && n <= 9 ? "one" : n == 2 || n == 3 ? "two" : n == 4 ? "few" : n == 6 ? "many" : "other";
      return n == 1 ? "one" : "other";
    },
    os: a,
    osa: e,
    pa: b,
    pap: a,
    pcm: c,
    pl: function pl2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2);
      if (ord) return "other";
      return n == 1 && v0 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) ? "few" : v0 && i != 1 && (i10 == 0 || i10 == 1) || v0 && i10 >= 5 && i10 <= 9 || v0 && i100 >= 12 && i100 <= 14 ? "many" : "other";
    },
    prg: function prg(n, ord) {
      var s = String(n).split("."), f2 = s[1] || "", v = f2.length, t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2), f100 = f2.slice(-2), f10 = f2.slice(-1);
      if (ord) return "other";
      return t0 && n10 == 0 || n100 >= 11 && n100 <= 19 || v == 2 && f100 >= 11 && f100 <= 19 ? "zero" : n10 == 1 && n100 != 11 || v == 2 && f10 == 1 && f100 != 11 || v != 2 && f10 == 1 ? "one" : "other";
    },
    ps: a,
    pt: function pt2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return "other";
      return i == 0 || i == 1 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    pt_PT: function pt_PT(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return "other";
      return n == 1 && v0 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    rm: a,
    ro: function ro(n, ord) {
      var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n100 = t0 && s[0].slice(-2);
      if (ord) return n == 1 ? "one" : "other";
      return n == 1 && v0 ? "one" : !v0 || n == 0 || n != 1 && n100 >= 1 && n100 <= 19 ? "few" : "other";
    },
    rof: a,
    ru: function ru2(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) ? "few" : v0 && i10 == 0 || v0 && i10 >= 5 && i10 <= 9 || v0 && i100 >= 11 && i100 <= 14 ? "many" : "other";
    },
    rwk: a,
    sah: e,
    saq: a,
    sat: f,
    sc: function sc(n, ord) {
      var s = String(n).split("."), v0 = !s[1];
      if (ord) return n == 11 || n == 8 || n == 80 || n == 800 ? "many" : "other";
      return n == 1 && v0 ? "one" : "other";
    },
    scn: function scn(n, ord) {
      var s = String(n).split("."), v0 = !s[1];
      if (ord) return n == 11 || n == 8 || n == 80 || n == 800 ? "many" : "other";
      return n == 1 && v0 ? "one" : "other";
    },
    sd: a,
    sdh: a,
    se: f,
    seh: a,
    ses: e,
    sg: e,
    sh: function sh(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2), f10 = f2.slice(-1), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) || f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14) ? "few" : "other";
    },
    shi: function shi(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return "other";
      return n >= 0 && n <= 1 ? "one" : t0 && n >= 2 && n <= 10 ? "few" : "other";
    },
    si: function si(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "";
      if (ord) return "other";
      return n == 0 || n == 1 || i == 0 && f2 == 1 ? "one" : "other";
    },
    sk: function sk(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1];
      if (ord) return "other";
      return n == 1 && v0 ? "one" : i >= 2 && i <= 4 && v0 ? "few" : !v0 ? "many" : "other";
    },
    sl: function sl(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i100 = i.slice(-2);
      if (ord) return "other";
      return v0 && i100 == 1 ? "one" : v0 && i100 == 2 ? "two" : v0 && (i100 == 3 || i100 == 4) || !v0 ? "few" : "other";
    },
    sma: f,
    smi: f,
    smj: f,
    smn: f,
    sms: f,
    sn: a,
    so: a,
    sq: function sq(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
      if (ord) return n == 1 ? "one" : n10 == 4 && n100 != 14 ? "many" : "other";
      return n == 1 ? "one" : "other";
    },
    sr: function sr(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), i100 = i.slice(-2), f10 = f2.slice(-1), f100 = f2.slice(-2);
      if (ord) return "other";
      return v0 && i10 == 1 && i100 != 11 || f10 == 1 && f100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) || f10 >= 2 && f10 <= 4 && (f100 < 12 || f100 > 14) ? "few" : "other";
    },
    ss: a,
    ssy: a,
    st: a,
    su: e,
    sv: function sv(n, ord) {
      var s = String(n).split("."), v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
      if (ord) return (n10 == 1 || n10 == 2) && n100 != 11 && n100 != 12 ? "one" : "other";
      return n == 1 && v0 ? "one" : "other";
    },
    sw: d,
    syr: a,
    ta: a,
    te: a,
    teo: a,
    th: e,
    ti: b,
    tig: a,
    tk: function tk(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1);
      if (ord) return n10 == 6 || n10 == 9 || n == 10 ? "few" : "other";
      return n == 1 ? "one" : "other";
    },
    tl: function tl(n, ord) {
      var s = String(n).split("."), i = s[0], f2 = s[1] || "", v0 = !s[1], i10 = i.slice(-1), f10 = f2.slice(-1);
      if (ord) return n == 1 ? "one" : "other";
      return v0 && (i == 1 || i == 2 || i == 3) || v0 && i10 != 4 && i10 != 6 && i10 != 9 || !v0 && f10 != 4 && f10 != 6 && f10 != 9 ? "one" : "other";
    },
    tn: a,
    to: e,
    tpi: e,
    tr: a,
    ts: a,
    tzm: function tzm(n, ord) {
      var s = String(n).split("."), t0 = Number(s[0]) == n;
      if (ord) return "other";
      return n == 0 || n == 1 || t0 && n >= 11 && n <= 99 ? "one" : "other";
    },
    ug: a,
    uk: function uk(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], t0 = Number(s[0]) == n, n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2), i10 = i.slice(-1), i100 = i.slice(-2);
      if (ord) return n10 == 3 && n100 != 13 ? "few" : "other";
      return v0 && i10 == 1 && i100 != 11 ? "one" : v0 && i10 >= 2 && i10 <= 4 && (i100 < 12 || i100 > 14) ? "few" : v0 && i10 == 0 || v0 && i10 >= 5 && i10 <= 9 || v0 && i100 >= 11 && i100 <= 14 ? "many" : "other";
    },
    und: e,
    ur: d,
    uz: a,
    ve: a,
    vec: function vec(n, ord) {
      var s = String(n).split("."), i = s[0], v0 = !s[1], i1000000 = i.slice(-6);
      if (ord) return n == 11 || n == 8 || n == 80 || n == 800 ? "many" : "other";
      return n == 1 && v0 ? "one" : i != 0 && i1000000 == 0 && v0 ? "many" : "other";
    },
    vi: function vi(n, ord) {
      if (ord) return n == 1 ? "one" : "other";
      return "other";
    },
    vo: a,
    vun: a,
    wa: b,
    wae: a,
    wo: e,
    xh: a,
    xog: a,
    yi: d,
    yo: e,
    yue: e,
    zh: e,
    zu: c
  });
})(plurals$1);
var plurals = /* @__PURE__ */ getDefaultExportFromCjs(plurals$1.exports);
var P = /* @__PURE__ */ _mergeNamespaces({
  __proto__: null,
  "default": plurals
}, [plurals$1.exports]);
var pluralCategories$1 = { exports: {} };
(function(module, exports) {
  var z = "zero", o = "one", t = "two", f = "few", m = "many", x = "other";
  var a = {
    cardinal: [o, x],
    ordinal: [x]
  };
  var b = {
    cardinal: [o, x],
    ordinal: [o, x]
  };
  var c = {
    cardinal: [x],
    ordinal: [x]
  };
  var d = {
    cardinal: [o, t, x],
    ordinal: [x]
  };
  (function(root, pluralCategories2) {
    Object.defineProperty(pluralCategories2, "__esModule", {
      value: true
    });
    module.exports = pluralCategories2;
  })(commonjsGlobal, {
    af: a,
    ak: a,
    am: a,
    an: a,
    ar: {
      cardinal: [z, o, t, f, m, x],
      ordinal: [x]
    },
    ars: {
      cardinal: [z, o, t, f, m, x],
      ordinal: [x]
    },
    as: {
      cardinal: [o, x],
      ordinal: [o, t, f, m, x]
    },
    asa: a,
    ast: a,
    az: {
      cardinal: [o, x],
      ordinal: [o, f, m, x]
    },
    bal: b,
    be: {
      cardinal: [o, f, m, x],
      ordinal: [f, x]
    },
    bem: a,
    bez: a,
    bg: a,
    bho: a,
    bm: c,
    bn: {
      cardinal: [o, x],
      ordinal: [o, t, f, m, x]
    },
    bo: c,
    br: {
      cardinal: [o, t, f, m, x],
      ordinal: [x]
    },
    brx: a,
    bs: {
      cardinal: [o, f, x],
      ordinal: [x]
    },
    ca: {
      cardinal: [o, m, x],
      ordinal: [o, t, f, x]
    },
    ce: a,
    ceb: a,
    cgg: a,
    chr: a,
    ckb: a,
    cs: {
      cardinal: [o, f, m, x],
      ordinal: [x]
    },
    cy: {
      cardinal: [z, o, t, f, m, x],
      ordinal: [z, o, t, f, m, x]
    },
    da: a,
    de: a,
    doi: a,
    dsb: {
      cardinal: [o, t, f, x],
      ordinal: [x]
    },
    dv: a,
    dz: c,
    ee: a,
    el: a,
    en: {
      cardinal: [o, x],
      ordinal: [o, t, f, x]
    },
    eo: a,
    es: {
      cardinal: [o, m, x],
      ordinal: [x]
    },
    et: a,
    eu: a,
    fa: a,
    ff: a,
    fi: a,
    fil: b,
    fo: a,
    fr: {
      cardinal: [o, m, x],
      ordinal: [o, x]
    },
    fur: a,
    fy: a,
    ga: {
      cardinal: [o, t, f, m, x],
      ordinal: [o, x]
    },
    gd: {
      cardinal: [o, t, f, x],
      ordinal: [o, t, f, x]
    },
    gl: a,
    gsw: a,
    gu: {
      cardinal: [o, x],
      ordinal: [o, t, f, m, x]
    },
    guw: a,
    gv: {
      cardinal: [o, t, f, m, x],
      ordinal: [x]
    },
    ha: a,
    haw: a,
    he: d,
    hi: {
      cardinal: [o, x],
      ordinal: [o, t, f, m, x]
    },
    hnj: c,
    hr: {
      cardinal: [o, f, x],
      ordinal: [x]
    },
    hsb: {
      cardinal: [o, t, f, x],
      ordinal: [x]
    },
    hu: b,
    hy: b,
    ia: a,
    id: c,
    ig: c,
    ii: c,
    io: a,
    is: a,
    it: {
      cardinal: [o, m, x],
      ordinal: [m, x]
    },
    iu: d,
    ja: c,
    jbo: c,
    jgo: a,
    jmc: a,
    jv: c,
    jw: c,
    ka: {
      cardinal: [o, x],
      ordinal: [o, m, x]
    },
    kab: a,
    kaj: a,
    kcg: a,
    kde: c,
    kea: c,
    kk: {
      cardinal: [o, x],
      ordinal: [m, x]
    },
    kkj: a,
    kl: a,
    km: c,
    kn: a,
    ko: c,
    ks: a,
    ksb: a,
    ksh: {
      cardinal: [z, o, x],
      ordinal: [x]
    },
    ku: a,
    kw: {
      cardinal: [z, o, t, f, m, x],
      ordinal: [o, m, x]
    },
    ky: a,
    lag: {
      cardinal: [z, o, x],
      ordinal: [x]
    },
    lb: a,
    lg: a,
    lij: {
      cardinal: [o, x],
      ordinal: [m, x]
    },
    lkt: c,
    ln: a,
    lo: {
      cardinal: [x],
      ordinal: [o, x]
    },
    lt: {
      cardinal: [o, f, m, x],
      ordinal: [x]
    },
    lv: {
      cardinal: [z, o, x],
      ordinal: [x]
    },
    mas: a,
    mg: a,
    mgo: a,
    mk: {
      cardinal: [o, x],
      ordinal: [o, t, m, x]
    },
    ml: a,
    mn: a,
    mo: {
      cardinal: [o, f, x],
      ordinal: [o, x]
    },
    mr: {
      cardinal: [o, x],
      ordinal: [o, t, f, x]
    },
    ms: {
      cardinal: [x],
      ordinal: [o, x]
    },
    mt: {
      cardinal: [o, t, f, m, x],
      ordinal: [x]
    },
    my: c,
    nah: a,
    naq: d,
    nb: a,
    nd: a,
    ne: b,
    nl: a,
    nn: a,
    nnh: a,
    no: a,
    nqo: c,
    nr: a,
    nso: a,
    ny: a,
    nyn: a,
    om: a,
    or: {
      cardinal: [o, x],
      ordinal: [o, t, f, m, x]
    },
    os: a,
    osa: c,
    pa: a,
    pap: a,
    pcm: a,
    pl: {
      cardinal: [o, f, m, x],
      ordinal: [x]
    },
    prg: {
      cardinal: [z, o, x],
      ordinal: [x]
    },
    ps: a,
    pt: {
      cardinal: [o, m, x],
      ordinal: [x]
    },
    pt_PT: {
      cardinal: [o, m, x],
      ordinal: [x]
    },
    rm: a,
    ro: {
      cardinal: [o, f, x],
      ordinal: [o, x]
    },
    rof: a,
    ru: {
      cardinal: [o, f, m, x],
      ordinal: [x]
    },
    rwk: a,
    sah: c,
    saq: a,
    sat: d,
    sc: {
      cardinal: [o, x],
      ordinal: [m, x]
    },
    scn: {
      cardinal: [o, x],
      ordinal: [m, x]
    },
    sd: a,
    sdh: a,
    se: d,
    seh: a,
    ses: c,
    sg: c,
    sh: {
      cardinal: [o, f, x],
      ordinal: [x]
    },
    shi: {
      cardinal: [o, f, x],
      ordinal: [x]
    },
    si: a,
    sk: {
      cardinal: [o, f, m, x],
      ordinal: [x]
    },
    sl: {
      cardinal: [o, t, f, x],
      ordinal: [x]
    },
    sma: d,
    smi: d,
    smj: d,
    smn: d,
    sms: d,
    sn: a,
    so: a,
    sq: {
      cardinal: [o, x],
      ordinal: [o, m, x]
    },
    sr: {
      cardinal: [o, f, x],
      ordinal: [x]
    },
    ss: a,
    ssy: a,
    st: a,
    su: c,
    sv: b,
    sw: a,
    syr: a,
    ta: a,
    te: a,
    teo: a,
    th: c,
    ti: a,
    tig: a,
    tk: {
      cardinal: [o, x],
      ordinal: [f, x]
    },
    tl: b,
    tn: a,
    to: c,
    tpi: c,
    tr: a,
    ts: a,
    tzm: a,
    ug: a,
    uk: {
      cardinal: [o, f, m, x],
      ordinal: [f, x]
    },
    und: c,
    ur: a,
    uz: a,
    ve: a,
    vec: {
      cardinal: [o, m, x],
      ordinal: [m, x]
    },
    vi: {
      cardinal: [x],
      ordinal: [o, x]
    },
    vo: a,
    vun: a,
    wa: a,
    wae: a,
    wo: c,
    xh: a,
    xog: a,
    yi: a,
    yo: c,
    yue: c,
    zh: c,
    zu: a
  });
})(pluralCategories$1);
var pluralCategories = /* @__PURE__ */ getDefaultExportFromCjs(pluralCategories$1.exports);
var C = /* @__PURE__ */ _mergeNamespaces({
  __proto__: null,
  "default": pluralCategories
}, [pluralCategories$1.exports]);
var ranges$1 = { exports: {} };
(function(module, exports) {
  var a = function a2(start, end) {
    return "other";
  };
  var b = function b2(start, end) {
    return start === "other" && end === "one" ? "one" : "other";
  };
  var c = function c2(start, end) {
    return end || "other";
  };
  (function(root, pluralRanges) {
    Object.defineProperty(pluralRanges, "__esModule", {
      value: true
    });
    module.exports = pluralRanges;
  })(commonjsGlobal, {
    af: a,
    ak: b,
    am: c,
    an: a,
    ar: function ar(start, end) {
      return end === "few" ? "few" : end === "many" ? "many" : start === "zero" && end === "one" ? "zero" : start === "zero" && end === "two" ? "zero" : "other";
    },
    as: c,
    az: c,
    be: c,
    bg: a,
    bn: c,
    bs: c,
    ca: a,
    cs: c,
    cy: c,
    da: c,
    de: c,
    el: c,
    en: a,
    es: a,
    et: a,
    eu: a,
    fa: b,
    fi: a,
    fil: c,
    fr: c,
    ga: c,
    gl: c,
    gsw: c,
    gu: c,
    he: a,
    hi: c,
    hr: c,
    hu: c,
    hy: c,
    ia: a,
    id: a,
    io: a,
    is: c,
    it: c,
    ja: a,
    ka: function ka(start, end) {
      return start || "other";
    },
    kk: c,
    km: a,
    kn: c,
    ko: a,
    ky: c,
    lij: c,
    lo: a,
    lt: c,
    lv: function lv(start, end) {
      return end === "one" ? "one" : "other";
    },
    mk: a,
    ml: c,
    mn: c,
    mr: c,
    ms: a,
    my: a,
    nb: a,
    ne: c,
    nl: c,
    no: a,
    or: b,
    pa: c,
    pcm: a,
    pl: c,
    ps: c,
    pt: c,
    ro: function ro(start, end) {
      return end === "few" ? "few" : end === "one" ? "few" : "other";
    },
    ru: c,
    sc: c,
    scn: c,
    sd: b,
    si: function si(start, end) {
      return start === "one" && end === "one" ? "one" : "other";
    },
    sk: c,
    sl: function sl(start, end) {
      return end === "few" ? "few" : end === "one" ? "few" : end === "two" ? "two" : "other";
    },
    sq: c,
    sr: c,
    sv: a,
    sw: c,
    ta: c,
    te: c,
    th: a,
    tk: c,
    tr: c,
    ug: c,
    uk: c,
    ur: a,
    uz: c,
    vi: a,
    yue: a,
    zh: a,
    zu: c
  });
})(ranges$1);
var ranges = /* @__PURE__ */ getDefaultExportFromCjs(ranges$1.exports);
var R = /* @__PURE__ */ _mergeNamespaces({
  __proto__: null,
  "default": ranges
}, [ranges$1.exports]);
var Plurals = plurals || P;
var Categories = pluralCategories || C;
var RangePlurals = ranges || R;
var id = function id2(lc) {
  return lc === "pt-PT" ? "pt_PT" : lc;
};
var getSelector = function getSelector2(lc) {
  return Plurals[id(lc)];
};
var getCategories = function getCategories2(lc, ord) {
  return Categories[id(lc)][ord ? "ordinal" : "cardinal"];
};
var getRangeSelector = function getRangeSelector2(lc) {
  return RangePlurals[id(lc)];
};
var PluralRules$1 = getPluralRules__default["default"](Intl.NumberFormat, getSelector, getCategories, getRangeSelector);
var pluralRules = PluralRules$1;
var PluralRules = pluralRules;
function _interopDefaultLegacy(e) {
  return e && typeof e === "object" && "default" in e ? e : { "default": e };
}
var PluralRules__default = /* @__PURE__ */ _interopDefaultLegacy(PluralRules);
if (typeof Intl === "undefined") {
  if (typeof commonjsGlobal$1 !== "undefined") {
    commonjsGlobal$1.Intl = {
      PluralRules: PluralRules__default["default"]
    };
  } else if (typeof window !== "undefined") {
    window.Intl = {
      PluralRules: PluralRules__default["default"]
    };
  } else {
    commonjsGlobal$1.Intl = {
      PluralRules: PluralRules__default["default"]
    };
  }
  PluralRules__default["default"].polyfill = true;
} else if (!Intl.PluralRules || !Intl.PluralRules.prototype.selectRange) {
  Intl.PluralRules = PluralRules__default["default"];
  PluralRules__default["default"].polyfill = true;
} else {
  var test = ["en", "es", "ru", "zh"];
  var supported = Intl.PluralRules.supportedLocalesOf(test);
  if (supported.length < test.length) {
    Intl.PluralRules = PluralRules__default["default"];
    PluralRules__default["default"].polyfill = true;
  }
}
const trans = (i18nKey, count) => ({
  i18nKey,
  options: { count }
});
const max = (count) => Math.floor(count);
const i18nTimeAgo = (date) => {
  const elapsedSeconds = (Date.now() - new Date(date).getTime()) / 1e3;
  if (elapsedSeconds < 60)
    return trans("elapsed_time.seconds", max(elapsedSeconds));
  const elapsedMinutes = elapsedSeconds / 60;
  if (elapsedMinutes < 60)
    return trans("elapsed_time.minutes", max(elapsedMinutes));
  const elapsedHours = elapsedMinutes / 60;
  if (elapsedHours < 24) return trans("elapsed_time.hours", max(elapsedHours));
  const elapsedDays = elapsedHours / 24;
  if (elapsedDays < 30) return trans("elapsed_time.days", max(elapsedDays));
  const elapsedMonths = elapsedDays / 30;
  if (elapsedMonths < 12)
    return trans("elapsed_time.months", max(elapsedMonths));
  const elapsedYears = elapsedDays / 365;
  return trans("elapsed_time.years", max(elapsedYears));
};
function useFormattedTimeAgo(date) {
  const { t } = useTranslation("core");
  return useMemo(() => {
    const { i18nKey, options } = date ? i18nTimeAgo(date) : { i18nKey: "" };
    return t(i18nKey, options);
  }, [date, t]);
}
const DEFAULT_RESOURCES = {
  cs: {
    core: csCore,
    languages: csLanguages
  },
  de: {
    core: deCore,
    languages: deLanguages
  },
  en: {
    core: enCore,
    languages: enLanguages
  },
  es: {
    core: esCore,
    languages: esLanguages
  },
  fr: {
    core: frCore,
    languages: frLanguages
  },
  hu: {
    core: huCore,
    languages: huLanguages
  },
  it: {
    core: itCore,
    languages: itLanguages
  },
  pt: {
    core: ptCore,
    languages: ptLanguages
  }
};
const { ContextProvider, useStore } = createState(
  "I18n",
  (set) => ({
    resources: DEFAULT_RESOURCES,
    registerResources: (resources) => {
      set((state) => {
        return {
          ...state,
          resources: merge(state.resources, resources)
        };
      });
    },
    i18nInstance: null,
    setI18nInstance: (newI18n) => {
      set((state) => ({
        ...state,
        i18nInstance: newI18n
      }));
    }
  }),
  (state, props) => ({
    ...state,
    ...props,
    resources: props.resources ? merge(state.resources, props.resources) : state.resources
  })
);
const initializeI18n = async (resources, locale, fallbackLocale) => {
  return await use(initReactI18next).init({
    resources,
    lng: locale,
    fallbackLng: fallbackLocale,
    interpolation: {
      escapeValue: false
    }
  });
};
function InnerElement({
  children,
  loadingElement
}) {
  const { locale, fallbackLocale } = usePage();
  const logger = useLogger();
  const resources = useStore((s) => s.resources);
  const i18nInstance = useStore((s) => s.i18nInstance);
  const setI18nInstance = useStore((s) => s.setI18nInstance);
  useEffect(() => {
    if (!i18nInstance) {
      initializeI18n(resources, locale, fallbackLocale).then((newInstance) => {
        setI18nInstance(newInstance);
      }).catch((e) => {
        void logger.error(e);
        setI18nInstance(null);
      });
    }
  }, [
    fallbackLocale,
    i18nInstance,
    locale,
    logger,
    resources,
    setI18nInstance
  ]);
  if (!i18nInstance) {
    return loadingElement;
  }
  return children;
}
function I18nProvider({
  children,
  loadingElement,
  ...props
}) {
  return /* @__PURE__ */ jsx(ContextProvider, { ...props, children: /* @__PURE__ */ jsx(InnerElement, { loadingElement, children }) });
}
const useRegisterI18nResources = () => {
  return useStore((state) => state.registerResources);
};
export {
  DEFAULT_RESOURCES,
  I18nProvider,
  i18nTimeAgo,
  initializeI18n,
  useFormattedTimeAgo,
  useRegisterI18nResources
};
